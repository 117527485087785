<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-clinicas" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="calendar3" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Agendamentos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">  
                        
                        <div class="row">
                          <div class=" pb-2">
                            <div  >   
                                <b-button variant="light"  v-on:click="opentelaagendamentocad('Agendamentoscad')" >  
                                    <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                                </b-button>
                                <div class="box-clinicas-list">
                                    <div  class="box-clinicas-list-item row " style="max-height:none"  >
                                        <div class="box-clinicas-list-item-img col-4"> 
                                            <b-avatar variant="info" :src="item.foto"  size="4rem"></b-avatar>
                                        </div>
                                        <div class="box-clinicas-list-item-title col-8">
                                            <div class="box-clinicas-list-item-title-pd">
                                                <div class="box-clinicas-list-item-title-00">{{ item.name }} </div>
                                                <div class="box-clinicas-list-item-title-01">{{ item.end }}</div>  
                                            </div>
                                        </div> 
                                    </div> 
                                </div> 
                                <div  v-for="itemx in item.procedimentos" :key="itemx.id" :id="itemx.id" class="box-clinicas-list-item row pb-2 pt-2" v-on:click="openclinicaproc(itemx.id)" >
                                    <div class="box-clinicas-list-item-img col-4"> 
                                        <b-avatar variant="info" :src="itemx.foto"  size="4rem"></b-avatar>
                                    </div>
                                    <div class="box-clinicas-list-item-title col-8">
                                        <div class="box-clinicas-list-item-title-pd">
                                            <div class="box-clinicas-list-item-title-00 pt-2"><b>{{ itemx.name }}</b> </div> 
                                        </div>
                                    </div> 
                                </div>

                            </div>
                          </div>
                        </div>
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAgendamentoscade',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false, 
      token: 0,
      cod: 0, 
      item: {} 
    };
  },
  methods: {
    openclinicaproc(id) {
      this.isLoading = true;  
      
      sessionStorage.setItem("clinicasproccod", id);
      barramento.$emit('alterarTela', 'Agendamentoscadp'); 
       
    },
    opentelaagendamentocad(tela) {
      barramento.$emit('alterarTela', tela); 
    }, 
    onChangeCidade(event) {
      console.log(event.target.value); 
      this.filtro = this.dados.clinicas.filter(function(obj,) { return obj.cidade == event.target.value; }); 
      console.log(this.filtro); 
    }  
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 

    const text = sessionStorage.getItem("clinicascod");
    const obj = JSON.parse(text);
    
    this.item = obj[0]

    this.isLoading = false
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   
 
</style>