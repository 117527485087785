<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="card-text" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Anamneses
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic" style="text-align:center">  <br><br>
                        <div class="row">
                            Você teve alguma mudança que deseja informar depois da sua última anamnese?  
                        </div><br>
                        <div class="row">
                            <div class="col-6">
                                <b-button variant="outline-primary">Sim</b-button>
                            </div>
                            <div class="col-6">
                                <b-button variant="success">Não</b-button> 
                            </div>
                        </div>
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAnamnesesconf',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isSaldo: false,
      isSaldovisualizar: false,
      saldo: 0,
      token: 0,
      cod: 0,
      info: '',
      class: {
        id: 1,
        name: '',
        foto: ''
      } 
    };
  },
  methods: {
    opentelascombos(tela) {
      barramento.$emit('alterarTela', tela); 
    }  
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 
    this.$http
      .post('dashboard', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
         this.class = response.data.class 
         this.token = response.data.token  
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   
</style>