<template>
  <div id="app">  
    <component :is="componenteativo" ></component> 
    <Footer v-show="isFooter"  />
  </div>
</template>

<script> 
import barramento from '@/barramento'

import Agendamentos from './views/Agendamentos.vue'
import Agendamentositem from './views/Agendamentositem.vue'
import Agendamentoscad from './views/Agendamentoscad.vue'
import Agendamentoscade from './views/Agendamentoscade.vue'
import Agendamentoscadp from './views/Agendamentoscadp.vue'
import Anamneses from './views/Anamneses.vue'
import Anamnesescad from './views/Anamnesescad.vue'
import Anamnesesconf from './views/Anamnesesconf.vue'
import Anamnesesinfo from './views/Anamnesesinfo.vue'
import Combos from './views/Combos.vue'
import Combositem from './views/Combositem.vue'
import Cashback from './views/Cashback.vue'
import Qrcode from './views/Qrcode.vue'    
import Login from './views/Login.vue'
import Menu from './views/Menu.vue'
import Meucadastro from './views/Meucadastro.vue'
import Notificacoes from './views/Notificacoes.vue'
import Notificacoesitem from './views/Notificacoesitem.vue' 
import Cadastro from './views/Cadastro.vue'
import Validacaoemail from './views/Validacaoemail.vue'  


import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: { 
    Anamneses, 
    Anamnesesconf,
    Anamnesescad,
    Anamnesesinfo,
    Agendamentos, 
    Agendamentositem,
    Agendamentoscad,
    Agendamentoscade,
    Agendamentoscadp,
    Combos,
    Combositem,
    Cashback,
    Qrcode,  
    Login, 
    Menu, 
    Meucadastro, 
    Notificacoes, 
    Notificacoesitem,  
    Cadastro,
    Validacaoemail,
    Footer,  
  },
  data() {
    return {
      componenteativo : 'Combos',
      tela: 'Combos',
      toke: '',
      isFooter: true,
      baseurlimg: 'https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/'
    };
  },
  created(){
    barramento.$emit('baseurlimg', this.baseurlimg)
      
    barramento.$on('alterarTela', name =>{
      this.toke = sessionStorage.getItem("apcefgotoken");
      switch (name) {

        case 'Notificacoes':
          if (this.toke!=null) {
            this.componenteativo = name
          }else{ this.componenteativo = 'Login' }
          this.isFooter = false
          break;

        case 'Notificacoesitem':
          if (this.toke!=null) {
            this.componenteativo = name
          }else{ this.componenteativo = 'Login' }
          this.isFooter = false
          break;
      
        case 'Meucadastro':
          if (this.toke!=null) {
            this.isFooter = true
            this.componenteativo = name
          }else{ this.componenteativo = 'Login'; this.isFooter = false }
          
          break;

        
        case 'Cashback':
          if (this.toke!=null) {
            this.componenteativo = 'Login'
          }else{ this.componenteativo = 'Login' }
          this.isFooter = false
          break;

         

        case 'Login':
          this.componenteativo = name
          this.isFooter = false 
          break;

        case 'Cadastro':
          this.componenteativo = name
          this.isFooter = false 
          break;

        case 'Menu':
          this.componenteativo = name
          this.isFooter = false 
          break;

        case 'Validacaoemail':
          this.componenteativo = name
          this.isFooter = false 
          break;

        case 'Dashboard':
          this.componenteativo = 'Combos'
          this.isFooter = true 
          break;
          /*
        case 'Combos':
          this.componenteativo = 'Login'
          this.isFooter = true 
          break;
        case 'Agendamentos':
          this.componenteativo = 'Login'
          this.isFooter = true 
          break;
        case 'Anamneses':
          this.componenteativo = 'Login'
          this.isFooter = true 
          break; 
*/
        default: 
          this.componenteativo = name
          this.isFooter = true
          break;
      }
       
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  margin: 0px;
}
</style>
