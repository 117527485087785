<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="calendar3" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Agendamentos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">   
                        <div class="box-agendamentos-info">
                            <div   class="box-agendamentos-info-item row"  >
                            
                                <div class="box-agendamentos-info-item-title col-12">
                                    <b-button variant="light"  v-on:click="opentelasagendamentos('Agendamentos')" >  
                                        <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                                    </b-button>
                                    <div class="box-agendamentos-info-item-title-pd"> 
                                        <div class="box-agendamentos-info-item-title-01"> <b>Clínica:</b>  {{ item.clinica }} </div>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Endereço:</b>  {{ item.clinicaend }} </div>
                                        <hr>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Pacote:</b> {{ item.pacote }}</div>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Sessão:</b> {{ item.sessoes }} </div>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Fez anamnese:</b> {{ item.anamnese }} </div>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Data:</b> {{ item.datasessao }} </div>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Horário:</b> {{ item.horasessao }} </div> 
                                        <hr>
                                        <div class="row" v-if="item.anamnese==='Não'">
                                            <div class="col-12" >
                                                <b-button variant="primary"  v-on:click="opentelasagendamentos('Anamneses')">Fazer anamnese</b-button>
                                            </div> 
                                        </div>
                                        <div class="row" v-if="item.anamnese==='Sim'"> 
                                            <div class="col-12">
                                                <b-button variant="success">Ver endereço no mapa</b-button>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row" >
                                            <div class="col-12" >
                                                <b-button variant="danger" style="width:100%"  v-on:click="cancelaragendamentos()">Cancelar agendamento</b-button>
                                            </div> 
                                        </div>
                                         
  
                                    </div>
                                </div> 
                            </div>  
                        </div>
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAgendamentositem',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false, 
      token: 0,
      cod: 0, 
      item: {} 
    };
  },
  methods: {
    opentelasagendamentos(tela) {
      barramento.$emit('alterarTela', tela); 
    },
    cancelaragendamentos(){
      
        this.isLoading = true  
        this.$http
        .post('token-agendamentos-cancelar', {
                cod: this.cod,  
                token: this.token,  
                id: this.item.id 
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
           if(response.data.erro==0){
                alert(response.data.isreturntxt) 
                barramento.$emit('alterarTela', 'Agendamentos'); 
            }else{
                alert(response.data.isreturntxt)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)


    }
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 

    
    const text = sessionStorage.getItem("agendamentocod");
    const obj = JSON.parse(text);
    
    this.item = obj[0]

    this.isLoading = false
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style> 
.box-agendamentos-info { padding: 10px 0px;  }
.box-agendamentos-info .box-agendamentos-info-item { margin: 0 !important; padding-top: 10px; }
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-title { padding-left: 0 !important; }
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-title-pd { padding: 10px 0; }
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-title-pd .box-agendamentos-info-item-title-00 { font-weight: bold; }
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-title-pd .box-agendamentos-info-item-title-01 { font-size: 14px;}
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-title-pd .box-agendamentos-info-item-title-02 { font-size: 12px; color: #1B3781; }
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-seta { padding-top: 40px; }
.box-agendamentos-info .box-agendamentos-info-item .box-agendamentos-info-item-seta a { color: #ccc; font-size: 22px; }
.box-agendamentos-info .visto-check { background-color: #f1f1f1 !important; }
</style>