<template>
    <div  class="box-padrao-reallaser-01"  id="app-cadastro-validacao-email">
      <Loading v-show="isLoading" />
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">
                    <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-02.png"   v-on:click="openpagesvalidar('Login')" />
                </div>
                
                <div class=" box-form-logo" id="box-form-logo">
                    <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-03.png" />
                </div>

                <div class="box-form-dinamic" id="box-form-dinamic">
                    <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02" >
                        <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/cad-01.png" />
                    </div>
                    <div class="box-form-dinamic-00 " v-show="IsReturn" id="box-form-dinamic-login">
                         <form>
                            <div class="mb-5 mt-5">
                                <h3>E-mail validado com  sucesso!</h3> 
                            </div>
                            
                            <button type="button"   v-on:click="openpagesvalidar('Login')" class="btn btn-primary">Fazer Login</button>
                        </form>
                    </div>  
                    <div class="box-form-dinamic-00 " v-show="isLogin" id="box-form-dinamic-login">
                        <form method="post" id="form-validar-cod" @submit.prevent="validarcodigo()" >
                            <div class="mb-5 mt-5">
                                <label for="codigo" class="form-label">Código de validação</label>
                                <input type="text" class="form-control" name="codigo" v-model="email.codigo" id="codigo" required > 
                                <div class="form-text " > 
                                    Caso não tenha encontrado nosso e-mail na Caixa de Entrada, verifique na sua Caixa de Spam.   
                                </div>
                            </div>
                            
                            <button type="submit"  class="btn btn-primary">Finalizar Cadastro</button>
                        </form>
                    </div>  
                </div>
            </div>
    </div>
</template>

<script>
import barramento from '@/barramento';
import Loading from '../components/Loading.vue'

export default {
  name: 'ViewValidaremail',  
  components:{  
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isLogin: true,
      IsReturn: false, 
      baseurl: 'https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/',
      email: {
        codigo: '' 
      } 
    };
  },
  methods: {
    openpagesvalidar(name) {
        barramento.$emit('alterarTela', name); 
    },
    validarcodigo() { 
        this.isLoading = true;
        this.$http
        .post('token-email-validar', {
            codigo: this.email.codigo,
            email: sessionStorage.getItem("tokenemail"),
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => { 
          if(response.data.erro==0){
            this.isLogin = false
            this.IsReturn = true
          }else{
            alert('Erro! Tente novamente! erro:'+response.data.erro)
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    openpagescad(name){
      barramento.$emit('alterarTela', name); 
    },
  },
  mounted () {
     
    this.email.tokenemailis = sessionStorage.getItem("tokenemailis");
    this.email.name = sessionStorage.getItem("tokenname"); 
    this.isLoading = false
  }
}
</script>

<style>
 
  
</style>