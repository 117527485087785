<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="card-text" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Anamneses
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">  
                         <div>
                            <b-button variant="light"  v-on:click="opentelascombos('Anamneses')" >  
                                <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                            </b-button>
                         </div>
                         <hr>
                        <form class="form-horizontal" id="form"  method="post"  @submit.prevent="anamnesenewcadupdate()" >
                         
                              
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Já fez algum tipo de depilação?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumtipodepilacao"  name="algumtipodepilacao" id="algumtipodepilacao" @change="boxanamnesequestionario($event, 'algumtipodepilacao')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumtipodepilacaotrue">
                              <div class="col-md-4 form-label ">Se sim, quais tipos?</div>
                              <div class="col-md-8">
                                
                                  <input type="text" name="tiposdepilacao" id="tiposdepilacao" class="form-control" v-model="anamcad.tiposdepilacao"  >
                              </div> 
                            </div>
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Teve alguma reação alérgica?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumareacaoalergica" name="algumareacaoalergica" id="algumareacaoalergica" @change="boxanamnesequestionario($event, 'algumareacaoalergica')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumareacaoalergicatrue">
                              <div class="col-md-4 form-label ">Se sim, quais tipos?</div>
                              <div class="col-md-8">
                                
                                  <input type="text" name="tiposdereacoesalergicas" id="tiposdereacoesalergicas" class="form-control" v-model="anamcad.tiposdereacoesalergicas"  >
                              </div> 
                            </div>

                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Teve nódulos?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.tevenodulo" name="tevenodulo" id="tevenodulo" @change="boxanamnesequestionario($event, 'tevenodulo')" required>
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesetevenodulotrue">
                              <div class="col-md-4 form-label ">Se sim, quais tipos?</div>
                              <div class="col-md-8">
                                
                                  <input type="text" name="tevenodulolocal" id="tevenodulolocal" class="form-control" v-model="anamcad.tevenodulolocal"  > 
                              </div> 
                            </div>


                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Tem vasos e/ou varizes?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.varizes" name="varizes" id="varizes" >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Está grávida?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.gravida" name="gravida" id="gravida" >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Está amamentando?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.amamentando" name="amamentando" id="amamentando" >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Sofreu algum tipo de cirurgia?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.fezcirurgia" name="fezcirurgia" id="fezcirurgia" >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Tem algum problema hormonal?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumproblemahormonal" name="algumproblemahormonal" id="algumproblemahormonal" @change="boxanamnesequestionario($event, 'algumproblemahormonal')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumproblemahormonaltrue">
                              <div class="col-md-4 form-label ">Se sim, quais?</div>
                              <div class="col-md-8">
                                
                                  <input type="text" name="tiposproblemahormonal" id="tiposproblemahormonal" class="form-control" v-model="anamcad.tiposproblemahormonal"  > 
                              </div> 
                            </div>
                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Faz uso de algum tratamento de pele?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumtratamentopele" name="algumtratamentopele" id="algumtratamentopele" @change="boxanamnesequestionario($event, 'algumtratamentopele')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumtratamentopeleltrue">
                              <div class="col-md-4 form-label ">Local?</div>
                              <div class="col-md-8">
                                
                                  <input type="text" name="tratamentopelelocal" id="tratamentopelelocal" class="form-control" v-model="anamcad.tratamentopelelocal"  > 
                              </div> 
                            </div>
                            

                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Faz uso de ácidos?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumusodeacidos" name="algumusodeacidos" id="algumusodeacidos" @change="boxanamnesequestionario($event, 'algumusodeacidos')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumusodeacidostrue">
                              <div class="col-md-4 form-label ">Local?</div>
                              <div class="col-md-8">
                                
                                  <input type="text" name="usodeacidoslocal" id="usodeacidoslocal" class="form-control" v-model="anamcad.usodeacidoslocal"  > 
                              </div> 
                            </div>

                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Apresenta algum tipo de alteração na saúde?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.alteracaonasaude" name="alteracaonasaude" id="alteracaonasaude" >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>


                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Apresente algum tipo de lesão na pele?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumtipolesaopele" name="algumtipolesaopele" id="algumtipolesaopele" @change="boxanamnesequestionario($event, 'algumtipolesaopele')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumtipolesaopeletrue">
                              <div class="col-md-4 form-label ">Local?</div>
                              <div class="col-md-8"> 
                                  <input type="text" name="tiposlesaopele" id="tiposlesaopele" class="form-control" v-model="anamcad.tiposlesaopele"  > 
                              </div> 
                            </div>


                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">Faz uso de algum medicamento?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.algumusomedicamentos" name="algumusomedicamentos" id="algumusomedicamentos" @change="boxanamnesequestionario($event, 'algumusomedicamentos')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealgumusomedicamentostrue">
                              <div class="col-md-4 form-label ">Quais?</div>
                              <div class="col-md-8"> 
                                  <input type="text" name="medicamentos" id="medicamentos" class="form-control" v-model="anamcad.medicamentos"  > 
                              </div> 
                            </div>
                            
                            
                            <div class="form-group mb-4">
                              <div class="col-md-4 form-label ">É alérgico a algum medicamento?</div>
                              <div class="col-md-8">
                                
                                    <select class="form-control" v-model="anamcad.alergiamedicamentos" name="alergiamedicamentos" id="alergiamedicamentos" @change="boxanamnesequestionario($event, 'alergiamedicamentos')" required >
                                      <option value="0"   > Selecione ...</option>
                                      <option value="Sim"   > Sim</option>
                                      <option value="Não"   > Não</option>
                                    </select> 
                              </div>
                            </div>

                            
                            
                            <div class="form-group mb-4 display_none" id="boxanamnesealergiamedicamentostrue">
                              <div class="col-md-4 form-label ">Quais?</div>
                              <div class="col-md-8"> 
                                  <input type="text" name="medicamentosalergia" id="medicamentosalergia" class="form-control" v-model="anamcad.medicamentosalergia"  > 
                              </div> 
                            </div>

                            
                            <div class="form-group mb-4">
                              <div class="  col-md-12"> 
                                  <button type="submit" class="btn btn-primary"> 
                                    atualizar
                                  </button>
                              </div>
                            </div>
                      </form>

                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAnamnesesinfo',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false, 
      token: 0,
      cod: 0,
      info: '',
      dados: {},
      anamcad: {} 
    };
  },
  methods: {
    boxanamnesequestionario(event, box){
        const env = event.target.value;  
        if(env=='Sim'){
          document.querySelector('#boxanamnese'+box+'true').classList.remove('display_none')
        }else{
          document.querySelector('#boxanamnese'+box+'true').classList.add('display_none')
        }
    },
    boxanamnesequestionariox(val, box){
        const env = val;  
        if(env=='Sim'){
          document.querySelector('#boxanamnese'+box+'true').classList.remove('display_none')
        }else{
          document.querySelector('#boxanamnese'+box+'true').classList.add('display_none')
        }
    },
    opentelascombos(tela) {
      barramento.$emit('alterarTela', tela); 
    },
    anamnesenewcadupdate(){
   
      this.isLoading = true;
      this.$http
        .post('token-anamneses-update', this.anamcad, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => { 
          if(response.data.erro==0){
            alert(response.data.isreturntxt) 
            barramento.$emit('alterarTela', 'Anamneses'); 
          }else{
            alert(response.data.isreturntxt)
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    }  
  },
  mounted () {
  
    const text = sessionStorage.getItem("anamnesecod");
    const obj = JSON.parse(text);
    
    this.anamcad = obj[0]
    console.log(this.anamcad)

    this.boxanamnesequestionariox(this.anamcad.algumtipodepilacao, 'algumtipodepilacao');
    this.boxanamnesequestionariox(this.anamcad.algumareacaoalergica, 'algumareacaoalergica');
    this.boxanamnesequestionariox(this.anamcad.algumproblemahormonal, 'algumproblemahormonal');
    this.boxanamnesequestionariox(this.anamcad.algumusodeacidos, 'algumusodeacidos');
    this.boxanamnesequestionariox(this.anamcad.algumtipolesaopele, 'algumtipolesaopele');
    this.boxanamnesequestionariox(this.anamcad.algumusomedicamentos, 'algumusomedicamentos');
    this.boxanamnesequestionariox(this.anamcad.alergiamedicamentos, 'alergiamedicamentos');


    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 
    this.isLoading = false
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style >
    
</style>