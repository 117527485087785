<template>
    <div  id="box-spinner-00-app">
        <div class="box-spinner-00-app-00 "  >
            <div>
                <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-03.png"  />
                <div>Verificando agenda...</div>
            </div>
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div> 
</template>


<script>
export default {
  name: 'AppLoadingx' 
}
</script>

   
<style>  
    #box-spinner-00-app { position: fixed; bottom: 0; top: 0; left: 0; right: 0; background-color: #cdbba6 !important; width: 100%; z-index: 999; }
    #box-spinner-00-app .spinner-border { color: #090909 !important; }
    #box-spinner-00-app .box-spinner-00-app-00  { position: fixed; top: 40%; left: 30%; width: 150px; text-align: center; }
    #box-spinner-00-app .box-spinner-00-app-00  img {width: 150px; margin-bottom: 20px; }
</style>