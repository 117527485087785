<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-agendamentos" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="calendar3" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Agendamentos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">  
                         <div class="box-form-dinamic-00 "  >
                              <b-button variant="success"  v-on:click="agendamentonew()" style="width:100%">Novo agendamento</b-button> 
                        </div>

                        
                        <div v-show="isagendamentosativos" class="box-form-dinamic-00 "  >
                            <div style="font-size: 12px;">** Clique no agendamento e veja os detalhes. <hr></div>    
                            <div v-show="isagendamentosa">
                                <div class="box-agendamentos-list2">
                                    <div class="box-agendamentos-list-item2 "   >
                                        <div class="box-agendamentos-list-item-title ">
                                            <div class="box-agendamentos-list-item-title-pd">
                                                <div class="box-agendamentos-list-item-title-00">Aguardando anamnese</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-agendamentos-list">
                                    <div   class="box-agendamentos-list-item row"   v-for="itema in this.agendamentosa"  :key="itema.id" >
                                        <div class="box-agendamentos-list-item-title col-10"  v-on:click="openagendamento(itema.id)">
                                            <div class="box-agendamentos-list-item-title-pd">
                                                <div class="box-agendamentos-list-item-title-00"> {{ itema.pacote }} / {{ itema.sessoes }}</div>
                                                <div class="box-agendamentos-list-item-title-01"> {{ itema.clinica }} </div>
                                                <div class="box-agendamentos-list-item-title-02"> Data: {{ itema.datasessao }} Horário: {{ itema.horasessao }}</div>
                                            </div>
                                        </div> 
                                        <div class="box-agendamentos-list-item-img col-2"  v-on:click="openagendamento(itema.id)"> <br>
                                            <b-icon icon="arrow-right" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div v-show="isagendamentosp">
                                <div class="box-agendamentos-list2">
                                    <div class="box-agendamentos-list-item2 "   >
                                        <div class="box-agendamentos-list-item-title ">
                                            <div class="box-agendamentos-list-item-title-pd">
                                                <div class="box-agendamentos-list-item-title-00">Aguardando dia da sessão</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-agendamentos-list">
                                    <div   class="box-agendamentos-list-item row" v-for="itemp in this.agendamentosp"  :key="itemp.id"  >
                                        <div class="box-agendamentos-list-item-title col-10"  v-on:click="openagendamento(itemp.id)">
                                            <div class="box-agendamentos-list-item-title-pd">
                                                <div class="box-agendamentos-list-item-title-00"> {{ itemp.pacote }} / {{ itemp.sessoes }}</div>
                                                <div class="box-agendamentos-list-item-title-01"> {{ itemp.clinica }} </div>
                                                <div class="box-agendamentos-list-item-title-02"> Data: {{ itemp.datasessao }} Horário: {{ itemp.horasessao }}</div>
                                            </div>
                                        </div> 
                                        <div class="box-agendamentos-list-item-img col-2"  v-on:click="openagendamento(itemp.id)"> <br>
                                            <b-icon icon="arrow-right" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            
                            <div v-show="isagendamentos">
                                <div class="box-agendamentos-list3">
                                    <div class="box-agendamentos-list-item2 "   >
                                        <div class="box-agendamentos-list-item-title ">
                                            <div class="box-agendamentos-list-item-title-pd">
                                                <div class="box-agendamentos-list-item-title-00">Feitos</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-agendamentos-list">
                                    <div   class="box-agendamentos-list-item row"   v-for="itemx in this.agendamentosx"  :key="itemx.id" >
                                    
                                        <div class="box-agendamentos-list-item-title col-10"  v-on:click="openagendamento(itemx.id)">
                                            <div class="box-agendamentos-list-item-title-pd">
                                                <div class="box-agendamentos-list-item-title-00"> {{ itemx.pacote }} / {{ itemx.sessoes }}</div>
                                                <div class="box-agendamentos-list-item-title-01"> {{ itemx.clinica }} </div>
                                                <div class="box-agendamentos-list-item-title-02"> Data: {{ itemx.datasessao }} Horário: {{ itemx.horasessao }}</div>
                                            </div>
                                        </div>
                                        <div class="box-agendamentos-list-item-img col-2"  v-on:click="openagendamento(itemx.id)"> <br>
                                            <b-icon icon="check" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            
                        </div>
                        <div v-show="!isagendamentosativos" class="box-form-dinamic-00 "  >
                             
                            <div class="box-agendamentos-list">
                                <div class="box-agendamentos-list-item row"  > 
                                    <div class="box-agendamentos-list-item-title col-12">
                                        <div class="box-agendamentos-list-item-title-pd">
                                            <div class="box-agendamentos-list-item-title-00">
                                                No momento nenhuma agendamento foi feito.    
                                            </div> 
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                       

                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAgendamentos',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false, 
      token: 0,
      cod: 0, 
      isagendamentosa: false,
      isagendamentos: false,
      isagendamentosp: false,
      isagendamentosativos: false, 
      agendamentos: {},
      agendamentosa: {},
      agendamentosp: {},
      agendamentosx: {}
    };
  },
  methods: {
    agendamentonew(){
        barramento.$emit('alterarTela', 'Agendamentoscad');
    },
    opentelasdashboard(tela) {
      barramento.$emit('alterarTela', tela); 
    },  
    openagendamento(id) {
      this.isLoading = true;
      let agendamentositem = this.agendamentos.filter(function(obj,) { return obj.id == id; }); 
      //console.log(classificadoitem);
      //console.log(JSON.stringify(classificadoitem));
      
      sessionStorage.setItem("agendamentocod", JSON.stringify(agendamentositem));
      barramento.$emit('alterarTela', 'Agendamentositem'); 
 
    }, 
    openreservadashboard(id) {
      this.isLoading = true;
      let reservaitem = this.reserva.filter(function(obj,) { return obj.id == id; }); 
      //console.log(classificadoitem);
      //console.log(JSON.stringify(classificadoitem));
      
      sessionStorage.setItem("reservacod", JSON.stringify(reservaitem));
      barramento.$emit('alterarTela', 'Reservasitem'); 
 
    }
  },
  mounted () {
    sessionStorage.setItem("reallaserta", "Agendamentos"); 
    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 
    this.$http
      .post('token-agendamentos', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
         this.agendamentos = response.data.agendamentos 
         this.token = response.data.token  

         this.agendamentosp = this.agendamentos.filter(function(obj,) { return obj.status == 'AM'; }); 
         this.agendamentosx = this.agendamentos.filter(function(obj,) { return obj.status == 'F'; }); 
         this.agendamentosa = this.agendamentos.filter(function(obj,) { return obj.status == 'AG'; }); 

        console.log(this.agendamentosp);
        console.log(this.agendamentosx);
        console.log(this.agendamentosa);
        
         if(this.agendamentosx[0]){
            this.isagendamentos = true;
            this.isagendamentosativos = true;
         }
         if(this.agendamentosa[0]){
            this.isagendamentosa = true;
            this.isagendamentosativos = true;
         }
         if(this.agendamentosp[0]){
            this.isagendamentosp = true;
            this.isagendamentosativos = true;
         }
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)

      
     
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   
.box-agendamentos-list { padding: 10px 0px;  }
.box-agendamentos-list .box-agendamentos-list-item { margin: 0 !important; border-bottom: 1px solid #ccc; max-height: 105px; padding-top: 10px; }
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-title { padding-left: 0 !important; }
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-title-pd { padding: 10px 0; }
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-title-pd .box-agendamentos-list-item-title-00 { font-weight: bold; }
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-title-pd .box-agendamentos-list-item-title-01 { font-size: 14px;}
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-title-pd .box-agendamentos-list-item-title-02 { font-size: 12px; color: #1B3781; }
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-seta { padding-top: 40px; }
.box-agendamentos-list .box-agendamentos-list-item .box-agendamentos-list-item-seta a { color: #ccc; font-size: 22px; }
.box-agendamentos-list .visto-check { background-color: #f1f1f1 !important; }

.box-agendamentos-list-item-img img { height: 70px; border-radius: 50%; max-width: 100%; }

.box-agendamentos-list2 { padding: 5px 0px 0; }
.box-agendamentos-list2 .box-agendamentos-list-item2 { margin: 0 !important;    }
.box-agendamentos-list2 .box-agendamentos-list-item2 .box-agendamentos-list-item-title-pd { padding: 10px 0 0px; }
.box-agendamentos-list2 .box-agendamentos-list-item2 .box-agendamentos-list-item-title-pd .box-agendamentos-list-item-title-00 { font-weight: bold; color:#e46805; }

.box-agendamentos-list3 { padding: 15px 0px 0; }
.box-agendamentos-list3 .box-agendamentos-list-item2 { margin: 0 !important;    }
.box-agendamentos-list3 .box-agendamentos-list-item2 .box-agendamentos-list-item-title-pd { padding: 10px 0 0; }
.box-agendamentos-list3 .box-agendamentos-list-item2 .box-agendamentos-list-item-title-pd .box-agendamentos-list-item-title-00 { font-weight: bold; color:rgb(101, 101, 101) }
</style>