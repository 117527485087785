<template>
    <div class="box-padrao-reallaser-01" id="app-cadastro">
       <Loading v-show="isLoading" />
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">
                    <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-02.png"   v-on:click="openpagescadastrar('Login')" />
                </div>
                
                <div class=" box-form-logo" id="box-form-logo">
                    <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-03.png" />
                </div>

                <div class="box-form-dinamic" id="box-form-dinamic"> 
                    <div class="box-form-dinamic-00 " v-show="isLogin" id="box-form-dinamic-login">
                          <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02" >
                              <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/cad-01.png" />
                          </div>
                          <div class="box-app-pd-01-info" id="box-app-pd-01-info"  > 
                              <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">Informe seus dados para validação.</div>
                          </div>
                          <form method="post" id="form-validar-dados" @submit.prevent="cadastrar()"  >
                                <div class="mb-4">
                                    <label for="exampleInputEmail1" class="form-label">Nome</label>
                                    <input type="text" class="form-control" v-model="cadastro.name" id="name" name="name" placeholder="Nome Completo" required> 
                                </div>
                                <div class="mb-4">
                                    <label for="exampleInputEmail1" class="form-label">Email </label>
                                    <input type="email" class="form-control" v-model="cadastro.email" id="email" name="email"  required> 
                                </div>
                                <div class="mb-4">
                                    <label for="exampleInputPassword1" class="form-label">CPF</label>
                                    <input type="text" class="form-control" v-model="cadastro.cpf" id="cpf" name="cpf" placeholder="000.000.000-00" required>
                                    <div class="form-text  display_none" style="color: red;" id="return-cpf"></div>
                                </div>
                                <div class="mb-4">
                                    <label for="exampleInputPassword1" class="form-label">Celular</label>
                                    <input type="text" class="form-control" v-model="cadastro.celular" id="celular" name="celular" placeholder="Preferencialmente o whatsapp"   required>
                                </div>
                                <div class="mb-4 form-check">
                                    <input type="checkbox" class="form-check-input" id="validlgpd" required>
                                    <label class="form-check-label" for="validlgpd" style="font-size:12px" >Aceito as politicas de privacidade <a href="" style="color:#1B3781 ;">saiba mais</a></label>
                                </div>
                                <button type="submit" class="btn btn-primary">Próximo</button>
                            </form>
                    </div>  
                </div>
            </div>
    </div>
</template>

<script>
import barramento from '@/barramento';
import Loading from '../components/Loading.vue'

export default {
  name: 'ViewCadastro', 
  components:{  
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isLogin: true,
      IsReturn: false, 
      baseurl: 'https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/',
      cadastro: {
        name: '',
        email: '',
        cpf: '',
        celular: ''
      }
    };
  },
  methods: {
    openpagescadastrar(name) {
        barramento.$emit('alterarTela', name); 
    },
    cadastrar(){
      this.isLoading = true;
      this.$http
        .post('token-cadastro', {
            name: this.cadastro.name,
            email: this.cadastro.email,
            celular: this.cadastro.celular,
            cpf: this.cadastro.cpf
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => { 
          if(response.data.erro==0){
            sessionStorage.setItem("tokenemail", response.data.tokenemail);
            sessionStorage.setItem("tokenemailis", response.data.tokenemailis);
            sessionStorage.setItem("tokenvalidar", response.data.tokenvalidar);
            sessionStorage.setItem("tokenname", response.data.tokenname);

            barramento.$emit('alterarTela', 'Validacaoemail'); 
          }else{
            alert(response.data.erromsg)
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    }
  },
  openpagescad(name){
    barramento.$emit('alterarTela', name); 
  },
  mounted () {
      
    this.isLoading = false
  }
}
</script>

<style> 

 
</style>