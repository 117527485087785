<template>
    <div class="row" id="box-app-pd-01-title-t">
        <div class="col-4">
            <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                <div class="box-app-pd-01-info-titlex" id="box-app-pd-01-info-titlex">
                    <div class="feedxt1"> 
                        <div v-show="!isSaldovisualizar">R$ ****** </div>
                        <div v-show="isSaldovisualizar">R$ {{ saldo }} </div>  
                    </div>
                    <div class="feedxt2">
                        <svg xmlns="http://www.w3.org/2000/svg" v-show="!isSaldovisualizar"   v-on:click="opensaldo()" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                        </svg>  
                        <svg xmlns="http://www.w3.org/2000/svg" v-show="isSaldovisualizar"   v-on:click="closesaldo()" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                        </svg>
                    </div> 
                </div>
            </div>
        </div>
        <div class="col-4" style="text-align: center;"> 
            <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-03.png" style="width:56px; height:41px;   " />
        </div>
        <div class="col-4">
            <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">  
                <div id="btn-header-notificacoes">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16"  v-on:click="pagenotificacoes('Notificacoes')">
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                    </svg>
                    <i v-show="isNotificacoescount"  v-on:click="pagenotificacoes('Notificacoes')">{{notificacoescount}}</i>
                </div>  
            </div>
        </div>
    </div>
</template>

 
<script>
import barramento from '@/barramento'

export default {
  name: 'AppTitle', 
  data: function() {
    return {
        notificacoescount: 0,
        fotousuario: '',
        baseurl: 'https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/',
        isLoading: false,
        isToken: false,
        isFotousuario: false,
        isNotificacoescount: true,
        isSaldo: false,
        isSaldovisualizar: false,
        saldo: 0
    }
  },
  methods: {
    pagenotificacoes(name){   
        barramento.$emit('alterarTela', name); 
    }
  },
  mounted() {
    let  apcefgotoken = sessionStorage.getItem("apcefgotoken");
    if(apcefgotoken != null){  
        let  cod = sessionStorage.getItem("apcefgoid");
        this.$http
        .post('token-title', {
            cod: cod,
            token: apcefgotoken
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => {
            this.notificacoescount = response.data.notificacoescount
            this.isNotificacoescount = response.data.isnotificacoescount 

            this.isToken = true
            this.fotousuario = sessionStorage.getItem("apcefgofoto");
            if(this.fotousuario != 'null'){  
                this.isFotousuario = true
            }
            

        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    }
  },
  
  
}
</script>

<style>
#box-app-pd-01-title-t .box-app-pd-01-info {  text-align: center; color: #000000 !important;   padding: 5px 0; }
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-titlex { display: flex; margin: auto;  }
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-titlex .feedxt11 { width: 48%; text-align: right; padding-right: 2%; }
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-titlex .feedxt22 { width: 48%; text-align: left; padding-left: 2%; }
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-titlex .feedxt1 {font-size: 12px !important; padding-top: 5px;}
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-titlex .feedxt2 {padding-left: 10px; }
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-titlex .feedxt2 svg { width: 25px; height: 20px; }
#box-app-pd-01-title-t .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 12px; font-weight: bold; padding-bottom: 0px;  }



#box-app-pd-01-title-t { background-color:  #cdbba6 !important; margin: 0px !important; padding: 3px 0px; } 
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-visualizar  { text-align: center;}
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-visualizar svg { width: 20px; height: 20px;color: #000000; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-notificacoes  { text-align: center; float: right; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 #btn-header-notificacoes svg {  width: 20px; height: 20px; color: #000000;  }

 #btn-header-notificacoes i {   margin-left: -7px; margin-top: 3px;  background-color: #e46805 !important; border-radius: 45%; padding: 3px 4px; color: #FFF; font-size: 7px; vertical-align: bottom;}

#box-app-pd-01-title-t .box-app-pd-01-ico-01 { padding: 5px 8px 0px 0px; text-align: right; display: block; }
#box-app-pd-01-title-t .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#box-app-pd-01-title-t .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#box-app-pd-01-title-t .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#box-app-pd-01-title-t .box-app-pd-01-ico-02 svg { width: 45px; height: 45px; }
#box-app-pd-01-title-t .box-app-pd-01-ico-02 path { color: #000000; }
</style>