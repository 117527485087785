<template> 
    <div id="box-menu-footer" >
        <div class="menu-footer" >
            <div id="menu-footer" >
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-4">
                    <a class="    footer-menu-item-xd-00x" v-on:click="pagemenufooter(4, 'Menu')">
                        <div class="footer-menu-item-xd-00x-imgx" > 
                            <b-icon icon="list" style="width: 20px; height: 25px;" ></b-icon>
                        </div>
                        <span>Menu</span>
                    </a>
                </div> 
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-3">
                    <a class="    footer-menu-item-xd-00x" v-on:click="pagemenufooter(3, 'Anamneses')">
                        <div class="footer-menu-item-xd-00x-imgx" >
                            <b-icon icon="card-text" style="width: 20px; height: 25px;" ></b-icon>
                        </div>
                        <span>Anamneses</span>
                    </a>
                </div> 
                <div class="menu-footer-item ative-footer-menu" id="footer-menu-item-xd-00x-1">
                    <a class="   footer-menu-item-xd-00x " v-on:click="pagemenufooter(1, 'Agendamentos')">
                        <div class="footer-menu-item-xd-00x-imgx" >
                            <b-icon icon="calendar3" style="width: 20px; height: 25px;" ></b-icon>
                        </div>
                        <span>Agendamentos</span>
                    </a>
                </div> 
                <div class="menu-footer-item" id="footer-menu-item-xd-00x-5">
                    <a class="   footer-menu-item-xd-00x" v-on:click="pagemenufooter(5, 'Combos')">
                        <div class="footer-menu-item-xd-00x-imgx" >
                        <b-icon icon="bag" style="width: 20px; height: 25px;" ></b-icon>
                        </div>
                        <span>Combos</span>
                    </a>
                </div>  
            
            </div>
        </div>
    </div>  
</template>


<script>
import barramento from '@/barramento'

export default {
  name: 'AppFooter',
  props: ['componenteativo'],
  data: function() {
    return {
        idant: 1
    }
  },
  methods: {
    pagemenufooter(id, name){ 
       // document.getElementById('footer-menu-item-xd-00x-1').removeClass('ative-footer-menu');
        if(this.idant!=77){
        var element2 = document.getElementById('footer-menu-item-xd-00x-'+this.idant);
        element2.classList.remove("ative-footer-menu");
        }
        var element = document.getElementById('footer-menu-item-xd-00x-'+id);
        element.classList.add("ative-footer-menu");

        this.idant = id;

        barramento.$emit('alterarTela', name); 
    
    }
  },
  created() {
    barramento.$on('pagemenupdfooter', id =>{
  
        if(this.idant!=77){
        var element2 = document.getElementById('footer-menu-item-xd-00x-'+this.idant);
        element2.classList.remove("ative-footer-menu");
        }

        if(id!=77){
        var element = document.getElementById('footer-menu-item-xd-00x-'+id);
        element.classList.add("ative-footer-menu");
        }
        this.idant = id;

    })
  },
  
  
}
</script>

<style>
    #box-menu-footer { background-color:  #cdbba6 !important; position: fixed; width: 100%;  height: 55px; padding: 5px 8px 0;  z-index: 99;  bottom: 0; left: 0; border-radius: 0px 0px 0 0;}
    #box-menu-footer .menu-footer {  width: 100%;  }
    #box-menu-footer .menu-footer #menu-footer {  width: 100%; display: flex;  }
    #box-menu-footer .menu-footer #menu-footer .menu-footer-item { width: 25%; text-align: center; border-radius: 5px;}
    #box-menu-footer .menu-footer #menu-footer .menu-footer-item a {  text-decoration: none; color: #000000;  }
    #box-menu-footer .menu-footer #menu-footer .menu-footer-item span { font-size: 10px; color: #000000; }
    .footer-menu-item-xd-00x-imgx { height: 25px; padding-top: 3px;} 
</style>