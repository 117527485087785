<template>
    <div class="box-padrao-reallaser-01" id="app-login">
            <Loading v-show="isLoading" />
            <div class="box-app-pd-01" id="box-app-pd-01">
                
                
                <div class=" box-form-logo" id="box-form-logo">
                    <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-03.png" />
                </div>
                <div class="box-form-dinamic" id="box-form-dinamic"> 
                    <div class="box-form-dinamic-00 " v-show="isLogin" id="box-form-dinamic-login">
                          <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02" >
                              <img src="https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/login-01.png" />
                          </div>
                          <div class="box-app-pd-01-info" id="box-app-pd-01-info"  > 
                              <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">Informe seu e-mail e sua senha.</div>
                          </div>
                         <form> 
                            <div class="mb-4">
                                <label for="email" class="form-label">E-mail</label>
                                <input type="email" name="email" id="email" v-model="usuario.email" class="form-control"  > 
                            </div>
                            <div class="mb-4 row">
                                <div class="col-10">
                                  <label for="exampleInputPassword1" class="form-label">Senha</label>
                                  <input :type="xpassx" class="form-control" id="pass" v-model="usuario.pass" name="pass" >
                                </div>
                                <div class="col-2" style="padding-top: 40px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" v-show="!isPassisualizar" style="height:30px"   v-on:click="openpass()" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16" >
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
                                    </svg>  
                                    <svg xmlns="http://www.w3.org/2000/svg" v-show="isPassisualizar"  style="height:30px"   v-on:click="closepass()" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                </div>
                                <div class="col-12">
                                    <div class="form-text " > 
                                        <a href="javascript:void(0)" v-on:click="esqueciminhasenha()">Esqueci minha senha</a>    
                                    </div>
                                </div>
                                
                            </div>  
                            <button type="button" class="btn btn-primary" v-on:click="logar()" id="button-app-entrar"  >Entrar</button>
                            <hr>
                            <button type="button" class="btn btn-primary"  v-on:click="openpageslogin('Cadastro')" id="button-app-cadastrar">Cadastrar</button>

                            

                        </form>
                    </div>  
                </div>
            </div>
    </div>
</template>

<script>
import barramento from '@/barramento';
import Loading from '../components/Loading.vue'

export default {
  name: 'ViewLogin', 
  components:{ 
    Loading
  },
  data() {
    return {
      isPassisualizar: false,
      isLogin: true,
      IsReturn: false, 
      isLoading: false,
      baseurl: 'https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/',
      resposta: '',
      usuario: {
        email: '',
        pass: ''
      },
      vdados: {
        cname: '',
        cvalue: ''
      },
      xpassx: 'password'
    };
  },
  methods: {
    openpass(){
      this.xpassx = 'text'
      this.isPassisualizar = true
    },
    closepass(){
      this.xpassx = 'password'
      this.isPassisualizar = false
    },
    openpageslogin(name) {
      barramento.$emit('alterarTela', name); 
    },
    esqueciminhasenha(){
        
      this.isLoading = true;
      this.$http
        .post('token-recuperar-senha', { 
            email: this.usuario.email 
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => {
          if(response.data.erro==0){
             alert(response.data.resultmsg) 
          }else{
            alert('Erro! Tente novamente! ')
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    logar(){
        
      this.isLoading = true;
      this.$http
        .post('token-login', {
            pass: this.usuario.pass,
            email: this.usuario.email 
        }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
        })
        .then(response => {
          if(response.data.erro==0){
            sessionStorage.setItem("apcefgotoken", response.data.token);
            sessionStorage.setItem("apcefgonome", response.data.nome);
            sessionStorage.setItem("apcefgoemail", response.data.email);
            sessionStorage.setItem("apcefgoperfil", response.data.perfil);
            sessionStorage.setItem("apcefgofoto", response.data.foto);
            sessionStorage.setItem("apcefgoid", response.data.id);
            sessionStorage.setItem("apcefgocel", response.data.cel);
            
            barramento.$emit('alterarTela', 'Dashboard'); 
          }else{
            alert('Usuário ou senha está incorreto! Tente novamente!')
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    }
  } 
  
}
</script>

<style >

.box-padrao-reallaser-01 { background-color: #cdbba6 !important; }

.box-padrao-reallaser-01 .box-form-dinamic { background-color: #FFF;  padding: 20px 40px; border-radius:0px !important } 
.box-padrao-reallaser-01 .box-form-logo { width: 100%; text-align: center; padding: 20px 0 20px;  }
.box-padrao-reallaser-01 .box-form-logo img { width: 94px; height: 68px;} 

.box-padrao-reallaser-01 .box-app-pd-01-info {  text-align: center; color: #000000 !important; padding-bottom: 20px; }
.box-padrao-reallaser-01 .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 24px; font-weight: bold; }
.box-padrao-reallaser-01 .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 14px; font-weight: bold; padding-bottom: 10px;  }
 
.box-padrao-reallaser-01 .box-form-dinamic button { width: 100%; background-color: #cdbba6; border: 0px; padding: 10px;}
.box-padrao-reallaser-01 .box-form-dinamic input[type=text], input[type=password], input[type=email] { width: 100%; border: 1px solid #cdbba6; padding: 10px;}
  
.box-padrao-reallaser-01 .box-app-pd-01-ico-01 { padding: 30px 20px 12px; text-align: left !important; width: 100%; display: block !important; }
.box-padrao-reallaser-01 .box-app-pd-01-ico-01 img { width: 27px; height: 30px; }
.box-padrao-reallaser-01 .box-app-pd-01-ico-02 { padding-bottom: 25px; text-align: center; }
.box-padrao-reallaser-01 .box-app-pd-01-ico-02 img { width: 85px; height: 83px; }
 
.box-padrao-reallaser-01 .sub-buttons-login-app .box-help-00 { width: 82px; height: 82px; padding: 10px; text-align: center; border: 1px solid #1B3781; border-radius: 5px ; }
.box-padrao-reallaser-01 .sub-buttons-login-app .box-help-00 img { height: 31px ;}
.box-padrao-reallaser-01 .sub-buttons-login-app .box-help-00 .box-help-00-text  { font-size: 11px; color: #818181}

.box-padrao-reallaser-01 #form-text-esqueci-senha { width: 100%; text-align: right; }
.box-padrao-reallaser-01 #form-text-esqueci-senha a{ color:#000000 ; text-decoration:none;}
.box-padrao-reallaser-01 #button-app-entrar, button[type=submit] { width: 100%; background-color: #000000 !important; border: 0px;}


.display_none { display: none; }
</style>