<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-clinicas" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="calendar3" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Agendamentos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">  
                        <div class="row">
                            
                          <div class="col-3 ">
                            <div class=" pb-1">
                              <b-button variant="light"  v-on:click="opentelaagendamentos('Agendamentos')" >  
                                  <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                              </b-button>
                            </div> 
                          </div>
                          <div class="col-9 "> 
                            <select class="form-control" id="cidade" name="cidade"   @change="onChangeCidade($event)" > 
                              <option value="0">Cidades ...</option>
                              <option v-for="item in dados.cidades" :value="item.name" :key="item.name">
                                {{ item.name }}
                              </option> 
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 pb-2">
                            <div  >   
                                <div class="box-clinicas-list">
                                    <div  v-for="itemx in filtro" :key="itemx.id" :id="itemx.id" class="box-clinicas-list-item row" v-on:click="openclinica(itemx.id)" >
                                        <div class="box-clinicas-list-item-img col-4"> 
                                             <b-avatar variant="info" :src="itemx.foto"  size="4rem"></b-avatar>
                                        </div>
                                        <div class="box-clinicas-list-item-title col-8">
                                            <div class="box-clinicas-list-item-title-pd">
                                                <div class="box-clinicas-list-item-title-00">{{ itemx.name }} </div>
                                                <div class="box-clinicas-list-item-title-01">{{ itemx.end }}</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div> 

                            </div>
                          </div>
                        </div>
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAgendamentoscad',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isSaldo: false,
      isSaldovisualizar: false,
      saldo: 0,
      token: 0,
      cod: 0,
      info: '',
      dados: {},
      filtro: {} 
    };
  },
  methods: {
    openclinica(id) {
      this.isLoading = true;
      let clinica = this.dados.clinicas.filter(function(obj,) { return obj.id == id; });  
      
      sessionStorage.setItem("clinicascod", JSON.stringify(clinica));
      barramento.$emit('alterarTela', 'Agendamentoscade'); 
       
    },
    opentelaagendamentos(tela) {
      barramento.$emit('alterarTela', tela); 
    }, 
    onChangeCidade(event) {
      console.log(event.target.value); 
      this.filtro = this.dados.clinicas.filter(function(obj,) { return obj.cidade == event.target.value; }); 
      console.log(this.filtro); 
    }  
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 
    this.$http
      .post('token-agendamentos-new-dados', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
         this.dados = response.data.dados 
         this.token = response.data.token  
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   
#app-clinicas .box-clinicas-list { padding: 10px 0px;  }
#app-clinicas .box-clinicas-list .box-clinicas-list-item { margin: 0 !important; border-bottom: 1px solid #ccc; max-height: 105px; padding-top: 10px; }
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-title { padding-left: 0 !important; }
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-title-pd { padding: 10px 0; }
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-title-pd .box-clinicas-list-item-title-00 { font-weight: bold; }
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-title-pd .box-clinicas-list-item-title-01 { font-size: 14px;}
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-title-pd .box-clinicas-list-item-title-02 { font-size: 12px; color: #1B3781; }
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-seta { padding-top: 40px; }
#app-clinicas .box-clinicas-list .box-clinicas-list-item .box-clinicas-list-item-seta a { color: #ccc; font-size: 22px; }
#app-clinicas .box-clinicas-list .visto-check { background-color: #f1f1f1 !important; }
</style>