<template>
    <div> 
       <Loading v-show="isLoading" />
       <Loadingx v-show="isLoadingx" />
        <section id="app-clinicas" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="calendar3" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Agendamentos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">  
                        
                        <div class="row">
                          <div class=" pb-2">
                            <div  :class="boxcomboitem" >   
                                <b-button variant="light"  v-on:click="opentelaagendamentocadp('Agendamentoscade')" >  
                                    <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                                </b-button>
                                <div class="box-clinicas-list">
                                    <div  class="box-clinicas-list-item row " style="max-height:none"  >
                                        <div class="box-clinicas-list-item-img col-4"> 
                                            <b-avatar variant="info" :src="item.foto"  size="4rem"></b-avatar>
                                        </div>
                                        <div class="box-clinicas-list-item-title col-8">
                                            <div class="box-clinicas-list-item-title-pd">
                                                <div class="box-clinicas-list-item-title-00">{{ item.name }} </div>
                                                <div class="box-clinicas-list-item-title-01">{{ item.end }}</div>  
                                            </div>
                                        </div> 
                                    </div> 
                                </div> 
                                <div  v-for="itemx in procedimentos" :key="itemx.id" :id="itemx.id" class="box-clinicas-list-item row pb-2 pt-2" >
                                    <div class="box-clinicas-list-item-img col-4"> 
                                        <b-avatar variant="info" :src="itemx.foto"  size="4rem"></b-avatar>
                                    </div>
                                    <div class="box-clinicas-list-item-title col-8">
                                        <div class="box-clinicas-list-item-title-pd">
                                            <div class="box-clinicas-list-item-title-00 pt-2"><b>{{ itemx.name }}</b> </div> 
                                        </div>
                                    </div> 
                                </div>
                                <hr>
                                <div>
                                    <div><b>Data:</b></div>
                                    <div>
                                        <select class="form-control" id="datas" name="datas"   @change="onChangeDatas($event)" > 
                                            <option value="0">Selecione ...</option>
                                            <option v-for="itemx in datas" :value="itemx.data" :key="itemx.data">
                                                {{ itemx.dataf }}
                                            </option> 
                                        </select>
                                    </div>
                                    <hr>
                                </div>
                                <div>
                                    <div><b>Horário:</b></div>
                                     
                                    <div :class="boxhorx">selecione a data</div>
                                    <div :class="boxhor">
                                        <div class="row">
                                            <div class="col-6">
                                                <button type="button" v-on:click="boxhorarios(1)" class="btn btn-primary"> Turno da manhã</button>
                                            </div>
                                            <div class="col-6">
                                                <button type="button" v-on:click="boxhorarios(2)" class="btn btn-primary"> Turno da tarde</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 pb-4 pt-4">
                                                <div :class="boxhor1">
                                                    <div>Turno da manhã:</div>
                                                    <select class="form-control" id="horarioam" name="horarioam"   @change="onChangeHorarioAm($event)" > 
                                                        <option value="0">Selecione ...</option>
                                                        <option v-for="itemy in horariosam" :value="itemy.horario" :key="itemy.horario" >
                                                            {{ itemy.horariof }}
                                                        </option> 
                                                    </select>
                                                </div>
                                                <div :class="boxhor2">
                                                    <div>Turno da tarde:</div>
                                                    <select class="form-control" id="horarioam" name="horarioam"   @change="onChangeHorarioPm($event)" > 
                                                        <option value="0">Selecione ...</option>
                                                        <option v-for="itemy in horariospm" :value="itemy.horario" :key="itemy.horario">
                                                            {{ itemy.horariof }}
                                                        </option> 
                                                    </select>
                                                </div>
                                                
                                            </div>
                                             
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div> 
                                </div>
                                <div :class="boxbutton1">
                                      <div class="mb-4">
                                        <b-button variant="success"  v-on:click="concluiragendamento()" style="width:100%">Concluir Agendamento</b-button> 
                                      </div>
                                </div>
                                <div :class="boxbutton2">
                                        <div class="mb-4">
                                            <b>Valor:</b> R$ {{ procedimentos[0].valor }}
                                        </div>
                                      <div class="mb-4">
                                        <b-button variant="success"  v-on:click="boxpagamento()" style="width:100%">Efetuar Pagamento</b-button> 
                                      </div>
                                </div>
                            </div>
                            <div :class="'box-form-pagto '+boxfp">
                                <div class="row">
                                    <div class="col-3">

                                        <div class="pb-2">
                                            <b-button variant="light" v-on:click="closefp()" >  
                                                <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                                            </b-button>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="pb-2">
                                            <div><b>Forma de pagamento:</b></div>
                                            <select class="form-control" id="categoria" name="categoria"   @change="onChange($event)" > 
                                            <option value="0">Selecione ...</option>  
                                            <option value="1">Cartão de Crédito</option>  
                                            <option value="2">PIX</option>  
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                
                                
                                
                                <div>
                                    <div :class="'box-form-pagtos '+boxfp2" id="boxfp2"><hr>PIX</div>
                                    <div :class="'box-form-pagtos '+boxfp1" id="boxfp1"><hr>
                                        <div class="mb-4">
                                            <label for="cartname" class="form-label">Nome no cartão:</label>
                                            <input type="text" class="form-control" v-model="dados.cartname" id="cartname" name="cartname"  required> 
                                        </div>
                                        <div class="mb-4">
                                            <label for="cartcpf" class="form-label">CPF:</label>
                                            <input type="text" class="form-control" v-model="dados.cartcpf" id="cartcpf" name="cartcpf"  placeholder="000.000.000-00"  required> 
                                        </div>
                                        <div class="mb-4">
                                            <label for="cartnum" class="form-label">Número:</label>
                                            <input type="text" class="form-control" v-model="dados.cartnum" id="cartnum" name="cartnum"  required> 
                                        </div>
                                        <div class="mb-4">
                                            <label for="cartdata" class="form-label">Validade:</label>
                                            <input type="text" class="form-control" v-model="dados.cartdata" id="cartdata" name="cartdata"  placeholder="MMAAAA"  required> 
                                        </div>
                                        <div class="mb-4">
                                            <label for="cartcvc" class="form-label">CVC/CVV:</label>
                                            <input type="text" class="form-control" v-model="dados.cartcvc" id="cartcvc" name="cartcvc"  required> 
                                        </div>                                                
                                        <div class="mb-4">
                                            <b-button variant="success"  v-on:click="comprarsessao()" style="width:100%">Comprar Sessão</b-button> 
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div :class="boxresultcompra"></div>

                          </div>
                        </div>
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 
import Loadingx from '../components/Loadingx.vue' 


export default {
  name: 'ViewAgendamentoscadp',
  components:{ 
    Title,
    Loading,
    Loadingx 
  },
  data() {
    return {
        boxbutton1: '',
        boxbutton2: 'display_none',
        boxhor: 'display_none',
        boxhorx: '',
        boxhor1: 'display_none',
        boxhor2: 'display_none',
        boxfp1: 'display_none',
        boxfp2: 'display_none',
        boxfp: 'display_none',
        boxresultcompra: 'display_none',
        boxcomboitem: '',
        isLoading: true,
        isLoadingx: false,
        isMenu: false, 
        token: 0,
        cod: 0, 
        item: {},
        procedimentos: {},
        datas: {},
        horariosam: {},
        horariospm: {},
        datax: '',
        horario: '',
        verif: '',
        dados: {}
    };
  },
  methods: {
    onChange(event) {
      console.log(event.target.value); 
      let key = event.target.value;
      switch (key) {
        case '1':
          this.boxfp1 = '';
          this.boxfp2 = 'display_none';
          break;
        case '2': 
          this.boxfp2 = '';
          this.boxfp1 = 'display_none';
          break;
      
        default:
          alert('Selecione uma forma de pagamento.');
          this.boxfp2 = 'display_none';
          this.boxfp1 = 'display_none';
          break;
      }
    },
    closefp(){
      this.boxfp = 'display_none';
      this.boxcomboitem = '';
    },
    combobuy(){ 
      this.boxfp = '';
      this.boxcomboitem = 'display_none';
    },
    boxpagamento(){
        this.boxfp = '';
        this.boxcomboitem = 'display_none';
    },
    comprarsessao(){
        this.isLoading = true
        const idproc = sessionStorage.getItem("clinicasproccod");
         

        this.$http
        .post('token-agendamentos-new-buy', {
                cod: this.cod,  
                token: this.token,
                horasessao: this.horario,
                datasessao: this.datax,
                idproc: idproc,
                dados: this.item ,
                dadosx: this.dados  
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
           if(response.data.erro==0){
                alert(response.data.isreturntxt) 
                barramento.$emit('alterarTela', 'Agendamentos'); 
            }else{
                alert(response.data.isreturntxt)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    concluiragendamento(){
        this.isLoading = true
        const idproc = sessionStorage.getItem("clinicasproccod");
         

        this.$http
        .post('token-agendamentos-new', {
                cod: this.cod,  
                token: this.token,
                horasessao: this.horario,
                datasessao: this.datax,
                idproc: idproc,
                dados: this.item  
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
           if(response.data.erro==0){
                alert(response.data.isreturntxt) 
                barramento.$emit('alterarTela', 'Agendamentos'); 
            }else{
                alert(response.data.erromsg)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    boxhorarios(c){
        if(c==1){
            this.boxhor1 = ''
            this.boxhor2 = 'display_none'
        }else{
            this.boxhor2 = ''
            this.boxhor1 = 'display_none'
        }
    },
    openclinicaproc(id) {
      this.isLoading = true;  
      
      sessionStorage.setItem("clinicasproccod", id);
      barramento.$emit('alterarTela', 'Agendamentoscadp'); 
       
    },
    opentelaagendamentocadp(tela) {
      barramento.$emit('alterarTela', tela); 
    }, 
    onChangeDatas(event) {
      console.log(event.target.value); 
      if(event.target.value!=='0'){
        
        this.datax = event.target.value;

        /**** */
        this.isLoadingx = true
        const idproc = sessionStorage.getItem("clinicasproccod");
         

        this.$http
        .post('token-agendamentos-new-dados-agenda', {
                cod: this.cod,  
                token: this.token,
                data: this.datax, 
                idproc: idproc,
                dados: this.item 
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
           if(response.data.erro==0){
                this.horariosam = response.data.horarios
                this.horariospm = response.data.horariospm
                this.boxhor = ''
                this.boxhorx = 'display_none' 
            }else{
                alert(response.data.isreturntxt)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoadingx = false)
        /**** */
        
      }else{
        
        this.boxhor = 'display_none'
        this.boxhorx = ''
      }
      //this.filtro = this.dados.clinicas.filter(function(obj,) { return obj.cidade == event.target.value; }); 
      //console.log(this.filtro); 
    },
    onChangeHorarioAm(event){
        this.horario = event.target.value;
    },
    onChangeHorarioPm(event){
        this.horario = event.target.value;
    }  
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 

    const idproc = sessionStorage.getItem("clinicasproccod");
    const text = sessionStorage.getItem("clinicascod");
    const obj = JSON.parse(text);
    
    this.item = obj[0]
    console.log(this.item); 
    this.procedimentos = this.item.procedimentos.filter(function(obj,) { return obj.id == idproc; }); 
    this.datas = obj[0].agandamentos[0].datas; 

/*
    this.horariosam = this.procedimentos[0].horarios;
    this.horariospm = this.procedimentos[0].horariospm;*/
    console.log(this.procedimentos[0]); 
   

    this.isLoading = false


    this.$http
      .post('token-agendamentos-verific-proc', {
            cod: this.cod,  
            token: this.token,
            idproc: idproc 
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => { 
         this.verif = response.data.verif   
         if(this.verif==='S'){
            this.boxbutton1 = '';
            this.boxbutton2 = 'display_none';
         }else{
            this.boxbutton2 = '';
            this.boxbutton1 = 'display_none';
         }
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   
 
</style>