<template>
    <div>
       <Menuprincipal v-show="isMenu" />
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="person-fill" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Meu Cadastro
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">
                         
                        <div class="sub-menu-pd-01">
                             
                           
                          <div :class="'sub-me-pd-01-item '+isSPessoal" v-on:click="openBoxMeuCadastro('Pessoal')">
                              <div class="sub-me-pd-01-item-ico">
                                   
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                    </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Pessoal
                              </div>
                          </div>
                          <div :class="'sub-me-pd-01-item '+isSEnd" v-on:click="openBoxMeuCadastro('End')">
                              <div class="sub-me-pd-01-item-ico">
                                  
                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Endereço
                              </div>
                          </div> 
                          <div :class="'sub-me-pd-01-item '+isSDependentes" v-on:click="openBoxMeuCadastro('Dependentes')">
                              <div class="sub-me-pd-01-item-ico">
                                  <b-icon icon="bag" style="width: 20px; height: 25px;" ></b-icon>
                              </div>
                              <div class="sub-me-pd-01-item-txt">
                                  Compras
                              </div>
                          </div> 
                           
                        </div>
                        <div class="box-form-dinamic-00 " id="box-form-dinamic-meu-cadastro-pessoal">
                              <form v-show="isBPessoal" @submit.prevent="atualizaDadosPessoal()"> 
                                  
                                  
                                  <div class="mb-4 mt-4">
                                      <label for="name" class="form-label">Nome Completo <i class="red">*</i></label>
                                      <input type="text" v-model="dados.name" name="name" class="form-control" id="name" required> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="cpf" class="form-label">CPF <i class="red">*</i></label>
                                      {{dados.cpf}}  
                                  </div> 
                                  <div class="mb-4">
                                      <label for="datanasc" class="form-label">Data nascimento <i class="red">*</i></label>
                                      <input type="date" v-model="dados.datanasc"  name="datanasc" class="form-control" id="datanasc" required>  
                                  </div> 
                                  <hr>
                                  <div class="mb-4">
                                      <label for="cel" class="form-label">Celular (whatsapp)  <i class="red">*</i></label>
                                      <input type="text" v-model="dados.cel" name="cel" class="form-control" id="cel" required> 
                                  </div>  
                                  <div class="mb-4">
                                      <label for="email" class="form-label">E-mail <i class="red">*</i></label>
                                      <input type="email" v-model="dados.email" name="email" class="form-control" id="email" required> 
                                  </div>  
                                  <hr> 
                                  <div class="mb-4">
                                      <label for="pass" class="form-label">Alterar senha </label>
                                      <input type="password" v-model="dados.pass" name="pass" class="form-control" id="pass" > 
                                  </div> 
                                  
                                  <div class="mb-4">
                                    <div>
                                        <b-avatar variant="info" :src="dados.appfoto"  size="4rem"></b-avatar>
                                    </div>
                                      <label for="pass" class="form-label">Alterar foto </label>
                                      <input type="file" class="form-control"  id="file" name="file"  @change="processFile($event)"> 
                                  </div>   
                                  <hr>   

                                  <button type="submit" class="btn btn-primary" id="button-app-entrar"  >Salvar</button>
                                  
                                    

                              </form>
                              <form v-show="isBEnd" @submit.prevent="atualizaDadosEnd()">
                                    
                                   
                                  <div class="mb-4 mt-4">
                                      <label for="cep" class="form-label">CEP <i class="red">*</i></label>
                                      <input type="text" v-model="dados.cep" name="cep" class="form-control" id="cep" required> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="estado" class="form-label">UF <i class="red">*</i></label>
                                      <select class="form-control" v-model="dados.estado" id="estado" name="estado"   required>  
                                            <option v-for="estado in dados.estados" :value="estado.uf" :key="estado.id">
                                                {{ estado.uf }}
                                            </option> 
                                      </select> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="cidade" class="form-label">Cidade <i class="red">*</i></label>
                                      <input type="text" v-model="dados.cidade" name="cidade" class="form-control" id="cidade" required> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="bairro" class="form-label">Setor <i class="red">*</i></label>
                                      <input type="text" v-model="dados.bairro" name="bairro" class="form-control" id="bairro" required> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="end" class="form-label">Endereço <i class="red">*</i></label>
                                      <input type="text" v-model="dados.end" name="end" class="form-control" id="end" required> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="num" class="form-label">Número <i class="red">*</i></label>
                                      <input type="text" v-model="dados.num" name="num" class="form-control" id="num" required> 
                                  </div>
                                  <div class="mb-4">
                                      <label for="complemento" class="form-label">Complemento </label>
                                      <input type="text" v-model="dados.complemento" name="complemento" class="form-control" id="complemento" > 
                                  </div>
                                  
                                  <button type="submit" class="btn btn-primary" id="button-app-entrar"  >Salvar</button>
                              </form>

                              <div v-show="isBDependentes">
                                    <div>
                                        <div v-for="pacote in dados.pacotes" :value="pacote.id" :key="pacote.id">
                                            <div>
                                                <div><b>Pacote</b>: {{ pacote.name }}</div>
                                                <div><b>Valor</b>: TR$ {{ pacote.valor }}</div>
                                                <div><b>Data</b>: {{ pacote.data }}</div>
                                             </div>
                                            <hr>
                                        </div>   
                                    </div>
                              </div>


                              <div v-show="isBAssociar">
                                    <div>
                                          
                                    </div>
                              </div>
                        </div> 
                    </div>
                </div>

        </section>

        
    </div>
</template>

 
<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue'
import Menuprincipal from '../components/Menuprincipal.vue'


export default {
  name: 'ViewMeucadastro',
  components:{ 
    Title,
    Loading,
    Menuprincipal
  },
  data() {
    return {
      isLoading: true,
      isBPessoal: true,
      isSPessoal: 'sub-me-pd-01-ative',
      isBEnd: false,
      isSEnd: '',
      isBDependentes: false,
      isSDependentes: '',
      isBAssociar: false,
      isSAssociar: '',
      isMenu: false,
      file: null, 
      token: '',
      cod: 0,
      dados: {},
      dep: {}
    };
  },
  methods: { 
    uploadfoto() {   
        
    let formData = new FormData();
    
    formData.append('file', this.file);
    formData.append('cod', this.cod);
    formData.append('token', this.token); 

    this.$http
        .post('main/meu-cadastro-upload-foto.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => { 
            if(response.data.erro==0){ 
                sessionStorage.setItem("apcefgofoto", response.data.appfoto);
                this.dados.foto = response.data.foto  
            } 
        })
        .catch(error => {
            console.log(error)
            alert('Erro ao fazer upload da imagem! Tente novamente.')
        })
        .finally(()=>{

                
                this.$http
                .post('token-meu-cadastro-pessoal', {
                        cod: this.cod,  
                        token: this.token,  
                        name: this.dados.name,
                        email: this.dados.email,
                        pass: this.dados.pass,
                        foto: this.dados.foto,
                        cpf: this.dados.cpf, 
                        datanasc: this.dados.datanasc, 
                        cel: this.dados.cel,
                        tel: this.dados.tel 
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                .then(response => {
                    if(response.data.erro==0){
                        alert("Dados atualizados com sucesso!")
                        if(response.data.result==5){
                            alert(response.data.resultmsg)
                            sessionStorage.setItem("apcefgoperfil", response.data.perfil);
                            barramento.$emit('alterarTela', 'Dashboard'); 
                        }
                        this.dadosMeuCadastro()
                    }else{
                        alert(response.data.erromsg)
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => { this.isLoading = false })

        })
         
    },
    processFile(event) {
        this.file = event.target.files[0]
    },
    boxalterarsenha(){
        this.isLoading = true 
        barramento.$emit('alterarTela', 'Alterarfoto');
    },
    dadosMeuCadastro(){
        this.isLoading = true 
        this.$http
        .post('token-meu-cadastro', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
          if(response.data.erro==0){
            this.dados = response.data.dados 
            
          }else{
        alert(4)
            barramento.$emit('alterarTela', 'Login');
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    closeBoxMeuCadastro(){
        this.isBPessoal = false
        this.isBEnd = false
        this.isBDependentes = false
        this.isBAssociar = false

        
        this.isSPessoal = ''
        this.isSEnd = ''
        this.isSDependentes = ''
        this.isSAssociar = ''
    },
    openBoxMeuCadastro(box){
        this.closeBoxMeuCadastro()
        switch (box) { 
            case 'Pessoal':
                this.isSPessoal = 'sub-me-pd-01-ative'
                this.isBPessoal = true
                break;
            case 'End':
                this.isSEnd = 'sub-me-pd-01-ative'
                this.isBEnd = true
                break;
            case 'Dependentes':
                this.isSDependentes = 'sub-me-pd-01-ative'
                this.isBDependentes = true
                break;
            case 'Associar':
                this.isSAssociar = 'sub-me-pd-01-ative'
                this.isBAssociar = true
                break;
        
            default:
                this.isSPessoal = 'sub-me-pd-01-ative'
                this.isBPessoal = true
                break;
        }
    },
    excluirDependente(id){
        this.isLoading = true 
        this.$http
        .post('token-meu-cadastro-dependentes-excluir', {
                cod: this.cod,  
                token: this.token, 
                id: id,   
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            if(response.data.erro==0){
                alert("exclusão realizada com sucesso!")
                this.dados.dependentes = response.data.dados.dependentes
            }else{
                alert(response.data.erromsg)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => { this.isLoading = false })
    },
    atualizaDadosDependentes(){

        this.isLoading = true 
        this.$http
        .post('token-meu-cadastro-dependentes-new', {
                cod: this.cod,  
                token: this.token,  
                name: this.dep.name,
                cpf: this.dep.cpf,
                datanasc: this.dep.datanasc   
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            if(response.data.erro==0){
                alert("Parabéns, dependente cadastrado com sucesso!")
                this.dados.dependentes = response.data.dados.dependentes
            }else{
                alert(response.data.erromsg)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => { this.isLoading = false })
    },
    atualizaDadosEnd(){
        this.isLoading = true 
        this.$http
        .post('token-meu-cadastro-end', {
                cod: this.cod,  
                token: this.token,  
                estado: this.dados.estado,
                cidade: this.dados.cidade,
                cep: this.dados.cep,
                bairro: this.dados.bairro,
                num: this.dados.num,
                complemento: this.dados.complemento,
                end: this.dados.end,   
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {
            if(response.data.erro==0){
                alert("Dados atualizados com sucesso!")
                
                if(response.data.result==5){ 
                    alert(response.data.resultmsg) 
                    sessionStorage.setItem("apcefgoperfil", response.data.perfil);
                    barramento.$emit('alterarTela', 'Dashboard'); 
                }
                this.dadosMeuCadastro()
            }else{
                alert(response.data.erromsg)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => { this.isLoading = false })
    },
    atualizaDadosPessoal(){

        this.isLoading = true 

        
        if(this.file!==null){
            
            this.uploadfoto() 
            
        }else{
            
            this.$http
            .post('token-meu-cadastro-pessoal', {
                    cod: this.cod,  
                    token: this.token,  
                    name: this.dados.name,
                    email: this.dados.email,
                    pass: this.dados.pass, 
                    cpf: this.dados.cpf, 
                    datanasc: this.dados.datanasc, 
                    cel: this.dados.cel,
                    tel: this.dados.tel 
                }, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            .then(response => {
                if(response.data.erro==0){
                    alert("Dados atualizados com sucesso!")
                    if(response.data.result==5){
                        alert(response.data.resultmsg)
                        sessionStorage.setItem("apcefgoperfil", response.data.perfil);
                        barramento.$emit('alterarTela', 'Dashboard'); 
                    }
                    this.dadosMeuCadastro()
                }else{
                    alert(response.data.erromsg)
                }
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => { this.isLoading = false })
        }


    },
    openclassificado() {
      barramento.$emit('alterarTela', 'Classificadositem'); 
    }, 
    openemitirconvite() {
      barramento.$emit('alterarTela', 'Convitesitem'); 
    },
  },
  mounted () {
    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    }else{
        alert(1)
        barramento.$emit('alterarTela', 'Login');
    }

    if(token != null || token != 'null'){  
      this.$http
        .post('token-meu-cadastro', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
          if(response.data.erro==0){
            this.dados = response.data.dados
          }else{
        alert(2)
            barramento.$emit('alterarTela', 'Login');
          }
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.isLoading = false)
    }else{
        alert(3)
        barramento.$emit('alterarTela', 'Login');
    }


    
     sessionStorage.setItem("reallaserta", "Meucadastro"); 
  },
  created(){
    barramento.$emit('pagemenupdfooter', 77);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>

.sub-buttons-login-app .box-help-00 { width: 82px; height: 82px; padding: 10px; text-align: center; border: 1px solid #1B3781; border-radius: 5px ; }
.sub-buttons-login-app .box-help-00 img { height: 31px ;}
.sub-buttons-login-app .box-help-00 .box-help-00-text  { font-size: 11px; color: #818181}
 
.red { color: red;}
 

.sub-menu-pd-01 { width: 100%; display: flex;; margin: auto; height: 70px; border-radius: 8px; box-shadow:1px 1px 5px #DEDEDE; background-color: #FFF; padding: 10px; margin-top: 15px;}
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-ico { height: 30px; }
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-ico path { color: #000000; }
.sub-menu-pd-01 .sub-me-pd-01-item .sub-me-pd-01-item-txt { font-size: 13px; }
.sub-menu-pd-01 .sub-me-pd-01-item  { width: 70px; text-align: center; }

.sub-me-pd-01-ative { color: #FF671B !important; }
.sub-me-pd-01-ative path { color: #FF671B !important; }
 
 

.sub-me-pd-01-ative { color: #FF671B !important; }
.sub-me-pd-01-ative path { color: #FF671B !important; }
 
 
#app-classificadosy .box-app-pd-01-ico-01 { padding: 35px 8px 0px 0px; text-align: right; display: flex; }
#app-classificadosy .box-app-pd-01-ico-01 div { width: 25px; height: 30px; margin: 3px 6px;}
#app-classificadosy .box-app-pd-01-ico-01 img { width: 25px; height: 25px; }
#app-classificadosy .box-app-pd-01-ico-02 { padding: 30px 20px 12px 20px; text-align: left; }
#app-classificadosy .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

 
 

</style>