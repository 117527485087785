<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="bag" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Combos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic" id="box-form-dinamic">  
                        
                        <div :class="'box-agendamentos-info '+boxcomboitem">
                            <div   class="box-agendamentos-info-item row"  >
                            
                                <div class="box-agendamentos-info-item-title col-12">
                                    <b-button variant="light" v-on:click="opentelascombos('Combos')" >  
                                        <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                                    </b-button>
                                    <div class="box-agendamentos-info-item-title-pd"> 
                                        <div>
                                        <b-img :src="item.foto"  fluid alt="Responsive image"></b-img>
                                        </div>
                                        <hr>
                                        <div class="box-agendamentos-info-item-title-01"> <b>{{ item.name }}</b> </div>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Valor:</b> R$ {{ item.valor }} </div>
                                        <hr>
                                        <div class="box-agendamentos-info-item-title-01"> <b>Descrição:</b> 
                                            <div  v-html="item.txt">
                                                
                                            </div>
                                        </div>
                                        <hr>
                                        <div>
                                           <div><b>Procedimentos:</b></div>
                                           <div>
                                              <div  v-for="itemx in this.item.itens"  :key="itemx.id">
                                                  <div class="row">
                                                    <div class="col-9">
                                                      {{ itemx.name }}
                                                    </div>
                                                    <div class="col-3">
                                                      {{ itemx.sessoes }}
                                                    </div>
                                                  </div>
                                              </div>
                                           </div>
                                        </div>
                                        <hr>
                                        <div >  
                                             <b-button variant="success"  v-on:click="combobuy()" style="width:100%">Comprar</b-button> 
                                        </div>

                                        
                                         
  
                                    </div>
                                </div> 
                            </div>  
                        </div>
                        <div :class="'box-form-pagto '+boxfp">
                            <div class="row">
                              <div class="col-3">
                                  <div class="pb-2">
                                    <b-button variant="light" v-on:click="closefp()" >  
                                        <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                                    </b-button>
                                  </div>
                              </div>
                              <div class="col-9">
                                  <div class="pb-2">
                                      <div><b>Forma de pagamento:</b></div>
                                      <select class="form-control" id="categoria" name="categoria"   @change="onChange($event)" > 
                                        <option value="0">Selecione ...</option>  
                                        <option value="1">Cartão de Crédito</option>  
                                        <option value="2">PIX</option>  
                                      </select>
                                  </div>
                              </div>
                            </div>
                            
                            
                            
                            <div>
                                  <div :class="'box-form-pagtos '+boxfp2" id="boxfp2"><hr>PIX</div>
                                  <div :class="'box-form-pagtos '+boxfp1" id="boxfp1"><hr>
                                      <div class="mb-4">
                                          <label for="cartname" class="form-label">Nome no cartão:</label>
                                          <input type="text" class="form-control" v-model="dados.cartname" id="cartname" name="cartname"  required> 
                                      </div>
                                      <div class="mb-4">
                                          <label for="cartcpf" class="form-label">CPF:</label>
                                          <input type="text" class="form-control" v-model="dados.cartcpf" id="cartcpf" name="cartcpf"  placeholder="000.000.000-00"  required> 
                                      </div>
                                      <div class="mb-4">
                                          <label for="cartnum" class="form-label">Número:</label>
                                          <input type="text" class="form-control" v-model="dados.cartnum" id="cartnum" name="cartnum"  required> 
                                      </div>
                                      <div class="mb-4">
                                          <label for="cartdata" class="form-label">Validade:</label>
                                          <input type="text" class="form-control" v-model="dados.cartdata" id="cartdata" name="cartdata"  placeholder="MMAAAA"  required> 
                                      </div>
                                      <div class="mb-4">
                                          <label for="cartcvc" class="form-label">CVC/CVV:</label>
                                          <input type="text" class="form-control" v-model="dados.cartcvc" id="cartcvc" name="cartcvc"  required> 
                                      </div>                                                
                                      <div class="mb-4">
                                        <b-button variant="success"  v-on:click="combobuypagto()" style="width:100%">Efetuar Pagamento</b-button> 
                                      </div>
                                  </div>
                            </div>
                          </div>

                          <div :class="boxresultcompra"></div>
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewCombositem',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isSaldo: false,
      isSaldovisualizar: false,
      saldo: 0,
      token: 0,
      cod: 0,
      boxfp1: 'display_none',
      boxfp2: 'display_none',
      boxfp: 'display_none',
      boxresultcompra: 'display_none',
      boxcomboitem: '',
      item: {},
      dados:{},
      items: [
          {  procedimentos: '', 'sessões': '' }, 
        ]
    };
  },
  methods: {
    opentelascombos(tela) {
      barramento.$emit('alterarTela', tela); 
    },
    combobuypagto(){
      this.isLoading = true  
      this.$http
      .post('token-combos-new', {
              cod: this.cod,  
              token: this.token, 
              dados: this.item 
          }, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          })
      .then(response => {
          if(response.data.erro==0){
              alert(response.data.isreturntxt) 
              barramento.$emit('alterarTela', 'Agendamentos'); 
          }else{
              alert(response.data.erromsg)
          }
      })
      .catch(error => {
          console.log(error)
          this.errored = true
      })
      .finally(() => this.isLoading = false)

    },
    closefp(){
      this.boxfp = 'display_none';
      this.boxcomboitem = '';
    },
    combobuy(){ 
      this.boxfp = '';
      this.boxcomboitem = 'display_none';
    },
    onChange(event) {
      console.log(event.target.value); 
      let key = event.target.value;
      switch (key) {
        case '1':
          this.boxfp1 = '';
          this.boxfp2 = 'display_none';
          break;
        case '2': 
          this.boxfp2 = '';
          this.boxfp1 = 'display_none';
          break;
      
        default:
          alert('Selecione uma forma de pagamento.');
          this.boxfp2 = 'display_none';
          this.boxfp1 = 'display_none';
          break;
      }
    }
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 

    const text = sessionStorage.getItem("combocod");
    const obj = JSON.parse(text);
    
    this.item = obj[0]

    /*
    this.item.id = obj[0].id
    this.item.name = obj[0].name
    this.item.valor = obj[0].valor
    this.item.txt = obj[0].txt
    this.item.foto = obj[0].foto

     */
    this.isLoading = false
     
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   
</style>