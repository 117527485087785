<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="bag" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Combos
                        </div>
                    </div>
                    
                    <div class="box-form-dinamic-002x" >  
                        <div class="row">
                            <div class="col-6" v-for="item in this.combos"  :key="item.id">
                                <b-card
                                    title=""
                                    :img-src="item.foto"
                                    img-alt="Image"
                                    img-top
                                    tag="article"
                                    style="max-width: 20rem;"
                                    class="mb-2" 
                                     v-on:click="opencomboitem(item.id)"
                                >
                                    <b-card-text>
                                    {{ item.name }}
                                    </b-card-text>
 
                                </b-card>
                            </div> 
                        </div>
                        
                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewCombos',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      isLoading: true,
      isMenu: false,
      isSaldo: false,
      isSaldovisualizar: false,
      saldo: 0,
      token: 0,
      cod: 0,
      info: '',
      combos: {} 
    };
  },
  methods: {
    opentelascombos(tela) {
      barramento.$emit('alterarTela', tela); 
    },
    opencomboitem(id) {
      this.isLoading = true;
      let comboitem = this.combos.filter(function(obj,) { return obj.id == id; }); 
      //console.log(comboitem);
      //console.log(JSON.stringify(comboitem));
      
      sessionStorage.setItem("combocod", JSON.stringify(comboitem));
      barramento.$emit('alterarTela', 'Combositem'); 
      
    }
  },
  mounted () {

    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 
    this.$http
      .post('token-pacotes', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
        if(response.data.token!==0){
          this.combos = response.data.pacotes 
          this.token = response.data.token  
        }else{
          barramento.$emit('alterarTela', 'Login'); 
        }
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)

      
     sessionStorage.setItem("reallaserta", "Combos"); 
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })
  }
}
</script>

<style>
   .box-form-dinamic-002x { background-color: #FFF; padding: 15px 15px 50px; }
</style>