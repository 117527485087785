<template>
    <div id="app-menu-principal">
            <div class="box-app-pd-01" id="box-app-pd-01">
                <div class="row"> 
                    <div class="col-12"> 
                        <div class="box-app-pd-01-ico-01" id="box-app-pd-01-ico-01">  
                            <div id="btn-header-menu-close" v-on:click="closemenuprincipalx(telaanterior)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>  
                            </div> 
                        </div>
                    </div>
                    <div class="col-12" style="text-align: center; padding:20px 0"> 
                        <img :src="baseurl+'login-03.png'" style="width:84px; height:57px; margin-top:15px  " />
                    </div>
                </div>
                <div class="row" id="box-dados-user">
                    <div class="col-3">
                        <div class="box-app-pd-01-ico-02" id="box-app-pd-01-ico-02">
                            
                             <div v-show="isFotousuario"     v-on:click="pagenotificacoes('Meucadastro')">
                                <b-avatar variant="info" :src="fotousuario"  size="4rem"></b-avatar>
                            </div> 
                            <b-icon icon="person-circle" style="width: 50px; height: 50px; color:#000"  v-on:click="openmenuprincipal('Meucadastro')"  v-show="!isFotousuario"  ></b-icon>
                             
                        </div>
                    </div>
                    <div class="col-9"> 
                            <div class="box-app-pd-01-info" id="box-app-pd-01-info" v-on:click="openmenuprincipal('Meucadastro')">
                                <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">{{ nomeusuario }}</div>
                                <div class="box-app-pd-01-info-txt" id="box-app-pd-01-info-txt">{{ emailusuario }}</div>
                            </div>
                    </div> 
                </div>
                
                
                
                <div class="box-form-dinamic" id="box-form-dinamic">
                        
                    <div class="box-form-dinamic-00 box-menu-principal " id="box-menu-principal-00" >
                         

                        <div  class="row box-menu-principal-x0 " v-on:click="openmenuprincipal('Meucadastro')"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <b-icon icon="person-fill" style="width: 20px; height: 25px;" ></b-icon>
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >Cadastro</div> 
                                </div>
                            </div> 
                        </div> 

                        <div  class="row box-menu-principal-x0 " v-on:click="openmenuprincipal('Anamneses')"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <b-icon icon="card-text" style="width: 20px; height: 25px;" ></b-icon>
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >Anamneses</div> 
                                </div>
                            </div> 
                        </div> 
 
                        <div  class="row box-menu-principal-x0 " v-on:click="openmenuprincipal('Agendamentos')"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <b-icon icon="calendar3" style="width: 20px; height: 25px;" ></b-icon>
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >Agendamentos</div> 
                                </div>
                            </div> 
                        </div>  
                        <div  class="row box-menu-principal-x0 " v-on:click="openmenuprincipal('Combos')"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <b-icon icon="bag" style="width: 20px; height: 25px;" ></b-icon>
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >Combos</div> 
                                </div>
                            </div> 
                        </div>  
                        <div  class="row box-menu-principal-x0 " v-on:click="openmenuprincipal('Cashback')"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <b-icon icon="cash-coin" style="width: 20px; height: 25px;" ></b-icon>
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >Cashback</div> 
                                </div>
                            </div> 
                        </div> 
                        <div  class="row box-menu-principal-x0 "   v-on:click="closesessionapp()"  >
                            <div class="col-2">
                                <div class="box-menu-principal-img"  >
                                    <b-icon icon="box-arrow-left" style="width: 20px; height: 25px;" ></b-icon>
                                </div>
                            </div>
                            <div class="col-10"> 
                                <div class="box-menu-principal-title "  >
                                    <div >Sair</div> 
                                </div>
                            </div> 
                        </div>    
                          

                    </div>
                        
                        
                </div>
            </div>

    </div>
</template>

<script>
import barramento from "@/barramento";
export default {
    name: 'ViewMenu', 
    data: function() {
        return { 
            fotousuario: '',
            telaanterior: 'Combos',
            isLoading: false,
            isToken: false,
            baseurl: 'https://reallaser-api.sadesenvolvimento.com.br/app-reallaser-api/main/img/',
            isFotousuario: false,
            nomeusuario: 'Login',
            emailusuario: 'clique aqui e faça seu login.',
            menua: {},
            menud: {}
        }
    },
    methods: {
        closemenuprincipalx(name){
            barramento.$emit('alterarTela', name);
        },
        closemenuprincipal() {
            barramento.$emit('boxMenuprincipal', false);
        },
        openmenuprincipal(name) {
            barramento.$emit('alterarTela', name);
        }, 
        closesessionapp(){ 
            sessionStorage.clear();
            barramento.$emit('alterarTela', 'Login');  
        }
    },
    mounted() {
        let ta = sessionStorage.getItem("reallaserta"); 
        if(ta !== '' && ta !== null && ta !== 'null'){
            this.telaanterior = ta; 
            console.log(ta)
        }
        console.log(this.telaanterior)

        let apcefgotoken = sessionStorage.getItem("apcefgotoken"); 
        let perfil = sessionStorage.getItem("apcefgoperfil");
        this.$http
        .post('token-menu', {
            perfil: perfil,
            token: apcefgotoken
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => { 
            this.isToken = response.data.istoken
             
            this.fotousuario = sessionStorage.getItem("apcefgofoto"); 
            if(this.fotousuario != null || this.fotousuario != 'null'){   
                this.isFotousuario = true
            }else{
                this.isFotousuario = false
            }

            if(this.isToken){
                this.nomeusuario = sessionStorage.getItem("apcefgonome");
                this.emailusuario = sessionStorage.getItem("apcefgoemail");
            }
            
            this.menua = response.data.menua
            this.menud = response.data.menud
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
        
    },
    created() {
        this.fotousuario = sessionStorage.getItem("apcefgofoto"); 
        if(this.fotousuario != null || this.fotousuario != 'null'){   
            this.isFotousuario = true
        }else{
            this.isFotousuario = false
        }

        
        this.nomeusuario = sessionStorage.getItem("apcefgonome");
        this.emailusuario = sessionStorage.getItem("apcefgoemail");
        
    },
    
}
</script>

 
<style> 
    #app-menu-principal { background-color:  #cdbba6 !important;   }
    #app-menu-principal .box-form-dinamic { background-color: #FFF; border-radius: 0px 0px 0px 0; padding: 30px; }

    #app-menu-principal .box-app-pd-01-ico-01 { padding: 15px 8px 0px 0px; width: 100%; text-align: right;}  
    #app-menu-principal .box-app-pd-01-ico-02 { padding: 0px; text-align: right; }
    #app-menu-principal .box-app-pd-01-ico-02 img { width: 55px; height: 55px; }

    #app-menu-principal .box-app-pd-01-info {  text-align: left; color: #000; }
    #app-menu-principal .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 16px; padding-bottom: 0px; }
    #app-menu-principal .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 14px; font-weight: bold; padding-bottom: 0px;  }

    
        
    #app-menu-principal .box-form-dinamic .box-form-logo { width: 100%; text-align: center; padding-bottom: 30px;  }
    #app-menu-principal .box-form-dinamic .box-form-logo img { width: 166px; height: 36px; } 
    #app-menu-principal .box-form-dinamic button { width: 100%; background-color: #1B3781; border: 0px;}
    #app-menu-principal #button-app-entrar { width: 100%; background-color: #FF671B; border: 0px;}

    #app-menu-principal #box-dados-user  { padding-bottom: 20px; }
    #app-menu-principal .box-app-pd-01-ico-01 #btn-header-menu-close  svg {  color: #000; }  


    #app-menu-principal .box-menu-principal .desabilitados {  color: #D4D4D4 !important; }
    
    #app-menu-principal .box-menu-principal {  text-align: left; color: #000; }
    #app-menu-principal .box-menu-principal .box-menu-principal-x0 { padding: 10px 0; }
    #app-menu-principal .box-menu-principal .box-menu-principal-img img { max-width: 23px; } 
    #app-menu-principal .box-menu-principal .box-menu-principal-title { font-size: 16px; padding-bottom: 0px; } 

    
    #app-menu-principal .menu-ative-00 {  color: #e76904 !important; }

    .box-menu-principal-sessao { background: #FFF;   width: 100%;  height: 65px; z-index: 99;  bottom: 20px;   }

</style>