<template>
    <div> 
       <Loading v-show="isLoading" />
        <section id="app-anamneses" class="box-app-pd-02">
                <div class="box-app-pd-01" id="box-app-pd-01">
                    <Title />

                      
                    <div class="box-app-pd-01-info" id="box-app-pd-01-info"> 
                        <div class="box-app-pd-01-info-title" id="box-app-pd-01-info-title">
                            <b-icon icon="card-text" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                            Anamneses
                        </div>
                    </div>
                    <div class="box-form-dinamic" v-show="!boxverific1" id="box-form-dinamic"> 
                        
                        <b-button variant="light"  v-on:click="verificclose()" >  
                            <b-icon icon="arrow-left" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon>  
                        </b-button>
                        <hr>
                        <div class="row">
                            Você teve alguma mudança que deseja informar depois da sua última anamnese?  
                        </div><br>
                        <div class="row">
                            <div class="col-6">
                                <b-button variant="outline-primary"   v-on:click="openanamnesenewcad()">Sim</b-button>
                            </div>
                            <div class="col-6">
                                <b-button variant="success" v-on:click="anamnesecadanterior()">Não</b-button> 
                            </div>
                        </div>
                    </div>
                    <div class="box-form-dinamic"  v-show="boxverific1" id="box-form-dinamic">  
                        
                        
                        <div v-show="isanamnesesativos" class="box-form-dinamic-00 "  >
                            <div style="font-size: 12px;">** Clique na anamnese e responsa o questionário. <hr></div>    
                            <div v-show="isanamnesesp">
                                <div class="box-anamneses-list2">
                                    <div class="box-anamneses-list-item2 "   >
                                        <div class="box-anamneses-list-item-title ">
                                            <div class="box-anamneses-list-item-title-pd">
                                                <div class="box-anamneses-list-item-title-00">A fazer</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-anamneses-list">
                                    <div   class="box-anamneses-list-item row"   v-for="item in this.agendamentos"  :key="item.id">
                                        <div class="box-anamneses-list-item-title col-10"  v-on:click="openanamnesenew(item.id)">
                                            <div class="box-anamneses-list-item-title-pd">
                                                <div class="box-anamneses-list-item-title-00">Pacote: {{ item.pacote }}</div>
                                                <div class="box-anamneses-list-item-title-01">Sessão: {{ item.sessoes }}</div>
                                                <div class="box-anamneses-list-item-title-02">Clínica: {{ item.clinica }} </div>
                                            </div>
                                        </div> 
                                        <div class="box-anamneses-list-item-img col-2"  v-on:click="openanamnesenew(item.id)"> <br>
                                            <b-icon icon="arrow-right" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            
                            <div v-show="isanamneses">
                                <div class="box-anamneses-list3">
                                    <div class="box-anamneses-list-item2 "   >
                                        <div class="box-anamneses-list-item-title ">
                                            <div class="box-anamneses-list-item-title-pd">
                                                <div class="box-anamneses-list-item-title-00">Feitas</div> 
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                                <div class="box-anamneses-list">
                                    <div   class="box-anamneses-list-item row"   v-for="itemx in this.anamneses"  :key="itemx.id" >
                                    
                                        <div class="box-anamneses-list-item-title col-10"   v-on:click="openanamneseinfo(itemx.id)" >
                                            <div class="box-anamneses-list-item-title-pd">
                                                <div class="box-anamneses-list-item-title-00">Pacote: {{ itemx.pacote }}</div>
                                                <div class="box-anamneses-list-item-title-01">Sessão: {{ itemx.sessoes }}</div>
                                                <div class="box-anamneses-list-item-title-02">Clínica: {{ itemx.clinica }} </div>
                                            </div>
                                        </div>
                                        <div class="box-anamneses-list-item-img col-2"   v-on:click="openanamneseinfo(itemx.id)" > <br>
                                            <b-icon icon="card-text" style="width:30px; height: 30px;vertical-align: sub;" ></b-icon> 
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            
                        </div>
                        <div v-show="!isanamnesesativos" class="box-form-dinamic-00 "  >
                             
                            <div class="box-anamneses-list">
                                <div class="box-anamneses-list-item row"  > 
                                    <div class="box-anamneses-list-item-title col-12">
                                        <div class="box-anamneses-list-item-title-pd">
                                            <div class="box-anamneses-list-item-title-00">
                                                No momento nenhuma anamnese foi feita.    
                                            </div> 
                                        </div>
                                    </div> 
                                </div> 
                            </div>
                        </div>


                    </div>

                </div>

        </section>
 
    </div>
</template>


<script>
import barramento from "@/barramento";
import Title from '../components/Title.vue' 
import Loading from '../components/Loading.vue' 


export default {
  name: 'ViewAnamneses',
  components:{ 
    Title,
    Loading 
  },
  data() {
    return {
      codverific: 0,
      boxverific1: true,
      boxverific2: 'display_none',
      isLoading: true,
      isMenu: false, 
      token: 0,
      cod: 0, 
      isanamneses: false,
      isanamnesesp: false,
      isanamnesesativos: false, 
      anamneses: {},
      anamnesesp: {},
      agendamentos: {}
    };
  },
  methods: { 
    anamnesecadanterior(){
        this.isLoading = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");

        let cody = this.codverific;
        alert(cody)

        this.$http
        .post('token-anamneses-cad-anterior', {
                cod: this.cod,  
                token: this.token,
                cody: cody  
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        .then(response => {  
            if(response.data.erro==0){
                alert(response.data.isreturntxt) 
                barramento.$emit('alterarTela', 'Agendamentos');  

            }else{
                alert(response.data.isreturntxt)
            }
        })
        .catch(error => {
            console.log(error)
            this.errored = true
        })
        .finally(() => this.isLoading = false)
    },
    openanamnesenewcad(){
        
        let codx = this.codverific;
        this.isLoading = true;
        let agendamento = this.agendamentos.filter(function(obj,) { return obj.id == codx });  
        
        sessionStorage.setItem("anamnesecreatecod", JSON.stringify(agendamento));
        barramento.$emit('alterarTela', 'Anamnesescad');
    }, 
    verificclose(){
      this.boxverific1 = true 
    },
    verificopen(){ 
      this.boxverific1 = false 
    },
    opentelasdashboard(tela) {
      barramento.$emit('alterarTela', tela); 
    },  
    openanamnesenew(id) {
       
      if(this.anamneses){  
        this.codverific = id
        this.boxverific1 = false
      }else{ 
        this.isLoading = true;
        let agendamento = this.agendamentos.filter(function(obj,) { return obj.id == id; });  
        
        sessionStorage.setItem("anamnesecreatecod", JSON.stringify(agendamento));
        barramento.$emit('alterarTela', 'Anamnesescad'); 
      }
      
 
    }, 
    openanamneseinfo(id) {
      this.isLoading = true;
      let anamnese = this.anamneses.filter(function(obj,) { return obj.id == id; }); 
      //console.log(classificadoitem);
      //console.log(JSON.stringify(classificadoitem));
      
      sessionStorage.setItem("anamnesecod", JSON.stringify(anamnese));
      barramento.$emit('alterarTela', 'Anamnesesinfo'); 
 
    }
  },
  mounted () {
     
    const token = sessionStorage.getItem("apcefgotoken");
    if(token != null || token != 'null'){   
        this.isToken = true
        this.cod = sessionStorage.getItem("apcefgoid"); 
        this.token = sessionStorage.getItem("apcefgotoken");
    } 
    this.$http
      .post('token-anamneses', {
            cod: this.cod,  
            token: this.token  
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
      .then(response => {
         this.anamneses = response.data.anamneses 
         this.agendamentos = response.data.agendamentos 
         this.token = response.data.token  


        console.log(this.agendamentos);
         if(this.anamneses){
            this.isanamneses = true
            this.isanamnesesativos = true
         }
         if(this.agendamentos){
            this.isanamnesesp = true
            this.isanamnesesativos = true
         }
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.isLoading = false)

     
     sessionStorage.setItem("reallaserta", "Anamneses"); 
  },
  created(){
    barramento.$emit('pagemenupdfooter', 1);
    barramento.$on('boxMenuprincipal', statusm =>{
      this.isMenu = statusm 
    })

    
  }
}
</script>

<style>
  
.box-app-pd-02 { background-color: #cdbba6 !important; }
.box-app-pd-02 .box-form-dinamic { background-color: #FFF; border-radius: 0px; padding: 30px 30px 70px 30px; }
.box-app-pd-02 .box-form-dinamic .box-form-dinamic-00 { padding-top: 00px;   }
 
.box-app-pd-02 .box-app-pd-01-info {  text-align: center; color: #000; padding-top: 15px; }
.box-app-pd-02 .box-app-pd-01-info .box-app-pd-01-info-title { font-size: 22px; padding-top: 40px; padding-bottom: 20px; padding-left: 28px; text-align: left; }
.box-app-pd-02 .box-app-pd-01-info .box-app-pd-01-info-txt { font-size: 18px; font-weight: bold; padding-bottom: 0px;  }
  
 
.box-anamneses-list { padding: 10px 0px;  }
.box-anamneses-list .box-anamneses-list-item { margin: 0 !important; border-bottom: 1px solid #ccc; max-height: 105px; padding-top: 10px; }
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-title { padding-left: 0 !important; }
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-title-pd { padding: 10px 0; }
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-title-pd .box-anamneses-list-item-title-00 { font-weight: bold; }
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-title-pd .box-anamneses-list-item-title-01 { font-size: 14px;}
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-title-pd .box-anamneses-list-item-title-02 { font-size: 12px; color: #1B3781; }
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-seta { padding-top: 40px; }
.box-anamneses-list .box-anamneses-list-item .box-anamneses-list-item-seta a { color: #ccc; font-size: 22px; }
.box-anamneses-list .visto-check { background-color: #f1f1f1 !important; }

.box-anamneses-list-item-img img { height: 70px; border-radius: 50%; max-width: 100%; }

.box-anamneses-list2 { padding: 5px 0px 0; }
.box-anamneses-list2 .box-anamneses-list-item2 { margin: 0 !important;    }
.box-anamneses-list2 .box-anamneses-list-item2 .box-anamneses-list-item-title-pd { padding: 10px 0 0px; }
.box-anamneses-list2 .box-anamneses-list-item2 .box-anamneses-list-item-title-pd .box-anamneses-list-item-title-00 { font-weight: bold; color:#e46805; }

.box-anamneses-list3 { padding: 15px 0px 0; }
.box-anamneses-list3 .box-anamneses-list-item2 { margin: 0 !important;    }
.box-anamneses-list3 .box-anamneses-list-item2 .box-anamneses-list-item-title-pd { padding: 10px 0 0; }
.box-anamneses-list3 .box-anamneses-list-item2 .box-anamneses-list-item-title-pd .box-anamneses-list-item-title-00 { font-weight: bold; color:rgb(101, 101, 101) }
</style>